<template>
  <div>
    <v-dialog
      v-model="posPaymentDialog"
      :max-width="'900px'"
      @input="close"
      scrollable
      persistent
    >
      <v-card tile class="payment-model" v-if="calculateCartSummary">
        <v-card-text>
          <v-row class="pt-6">
            <v-col md="7">
              <v-card outlined>
                <v-card-title class="fw-700 heading-title">
                  <span>PAYMENT METHOD</span></v-card-title
                >
                <v-divider></v-divider>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-card outlined tile>
                        <v-btn
                          block
                          style="background-color:#F2F2F2;"
                          class="fw-500 fs-14"
                          >Total {{ parseFloat(paymentTotal) | toCurrency }} /
                          {{
                            discountPercentage && discountAmount
                              ? discountAmount - 0
                              : amount
                              ? amount - 0
                              : payInvoiceData.total | toCurrency
                          }}</v-btn
                        >
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-form ref="form">
                        <v-row>
                          <v-col cols="12" md="12" v-if="payInvoiceData">
                            <template>
                              <PaymentMethodNew
                                v-for="(method, index) in payments"
                                v-bind="method"
                                :index="index"
                                :key="`p_${index}`"
                                :isPosPayment="true"
                                :posTotal="payInvoiceData.total"
                                @remove="removePaymentMethod"
                                @refresh="refreshFormValidation"
                                @validate="validate"
                              ></PaymentMethodNew>
                            </template>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions
                  v-if="
                    !payments.find((x) => x.payment_method == 'Complementary')
                  "
                >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
              <div class="bottom-fix" style="margin-top:30px;">
                <v-row>
                  <v-col cols="12">
                    <v-btn outlined class="" absolute bottom @click="close()"
                      >Close</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col md="5">
              <v-card outlined class="order-summary">
                <v-card-title class="fw-700 heading-title"
                  ><span>ORDER SUMMARY</span></v-card-title
                >
                <v-divider></v-divider>
                <v-card-text class="pa-2">
                  <v-expansion-panels v-model="panel1Open">
                    <v-expansion-panel class="tab-seprate">
                      <v-expansion-panel-header class="tab-heading">
                        {{
                          customerInfo && customerInfo.first_name
                            ? customerInfo.first_name
                            : "NA"
                        }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content
                        class="tab-content"
                        v-if="cartItems"
                      >
                        <v-list
                          three-line
                          class="overflow-y-auto"
                          v-if="cartItems"
                        >
                          <v-list-item
                            v-for="(item, i) in cartItems"
                            :key="i"
                            ripple
                            @click="() => {}"
                          >
                            <v-list-item-avatar title height="40" width="40">
                              <view-image
                                :image="item.image"
                                :height="40"
                                :width="40"
                              ></view-image>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="text-body product-name"
                                >{{ item.name }}</v-list-item-title
                              >
                              <v-list-item-subtitle>
                                <div
                                  class="d-flex justify-space-between text-caption"
                                >
                                  <div class="font-weight-bold">
                                    <!-- // item.product.product_type_id == 6 || item.product.product_type_id == 3 
                                        // ? item.product.price
                                        // : item.price -->
                                    {{ item.price }}
                                    x {{ item.quantity }}
                                  </div>
                                  <div class="font-weight-bold">
                                    Tax {{ (item.tax_amount * item.quantity) | toCurrency }}
                                  </div>
                                  <div class="font-weight-bold">
                                    {{ ( (item.price  +  item.tax_amount) * item.quantity) | toCurrency }}
                                  </div>
                                </div>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
                <div class="cart-text-bottom">
                  <v-row>
                    <v-col cols="6" class="smaller-col"
                      ><div class="subheading">Sub Total</div></v-col
                    >
                    <v-col cols="6" class="smaller-col text-right">
                      <div class="subheading">
                        {{
                          (discount
                            ? discount.actual_price
                            : payInvoiceData.price) | toCurrency
                        }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="smaller-col"
                      ><div class="subheading">Tax Amount</div></v-col
                    >
                    <v-col cols="6" class="smaller-col text-right">
                      <div class="subheading">
                        {{
                          (discount ? discount.actual_tax : payInvoiceData.tax)
                            | toCurrency
                        }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      discount &&
                        discount.actual_price - payInvoiceData.price != 0
                    "
                  >
                    <v-col cols="5" class="smaller-col"
                      ><div class="subheading fw-700 green--text">
                        {{ payInvoiceData.promotion_name }}
                      </div></v-col
                    >
                    <v-col
                      cols="7"
                      class="smaller-col text-right"
                      v-if="discount"
                    >
                      <div class="subheading fw-700">
                        {{
                          (discount.actual_price - payInvoiceData.price)
                            | toCurrency
                        }}
                        +
                        {{
                          (discount.actual_tax - payInvoiceData.tax)
                            | toCurrency
                        }}
                        =
                        {{
                          (discount.actual_total - payInvoiceData.total)
                            | toCurrency
                        }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pb-6">
                    <v-col cols="6" class="smaller-col"
                      ><div class="subheading fw-700">Grand Total</div></v-col
                    >
                    <v-col cols="6" class="smaller-col text-right">
                      <div class="subheading fw-700">
                        <span
                          class="text-decoration-line-through pt-1"
                          v-if="
                            discount &&
                              discount.actual_price - payInvoiceData.price != 0
                          "
                        >
                          {{ discount.actual_total | toCurrency }}
                        </span>
                        {{ payInvoiceData.total | toCurrency }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    dense
                    v-if="
                      !payments.find((x) => x.payment_method == 'Complementary')
                    "
                  >
                    <v-col cols="6" class="smaller-col">
                      <v-text-field
                        class="placeholder-small"
                        v-model="discountAmount"
                        label="Special discount"
                        outlined
                        :prefix="currencyCode"
                        :rules="[
                          (v) => {
                            if (v) {
                              if (isNaN(v)) {
                                return 'Amount must be a number';
                              }
                            }
                            return true;
                          },
                        ]"
                        @keyup="specialDiscountChange('amount')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="smaller-col">
                      <v-text-field
                        class="placeholder-small"
                        v-model="discountPercentage"
                        suffix="%"
                        :rules="[
                          (v) => {
                            if (v) {
                              if (isNaN(v)) {
                                return 'Amount must be a number';
                              }
                              if (v > 100) {
                                return 'Percentage should be less than 100';
                              }
                            }
                            return true;
                          },
                        ]"
                        label="Percentage"
                        outlined
                        @keyup="specialDiscountChange('percentage')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-textarea
                        v-model="paymentNote"
                        label="Payment note"
                        rows="1"
                        outlined
                      ></v-textarea>
                    </v-col>
                    <v-col
                      cols="6"
                      v-if="
                        payInvoiceData &&
                          payInvoiceData.customerInfo &&
                          payInvoiceData.customerInfo.customer_id
                      "
                      style="padding-bottom:24px;"
                    >
                      <v-card outlined tile>
                        <v-btn
                          class="fw-400 fs-12 pl-1 pr-1"
                          block
                          :loading="loadingProducts"
                          :disabled="loadingProducts"
                          ><v-icon small>mdi-wallet</v-icon> CASH:
                          {{ wallet.cash | toCurrency }}</v-btn
                        >
                      </v-card>
                    </v-col>
                    <v-col
                      cols="6"
                      v-if="
                        payInvoiceData &&
                          payInvoiceData.customerInfo &&
                          payInvoiceData.customerInfo.customer_id
                      "
                    >
                      <v-card outlined tile>
                        <v-btn
                          class="fw-400 fs-12 pl-1 pr-1"
                          style="background-color:#F2F2F2;"
                          block
                          :loading="loadingProducts"
                          :disabled="loadingProducts"
                          @click="showWalletProducts"
                          ><v-icon small>mdi-wallet-travel</v-icon> Products :
                          {{ wallet.products }}</v-btn
                        >
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-row dense class="pa-2">
                    <v-col cols="12"
                      ><v-btn
                        block
                        class="block white--text blue-color large"
                        @click="payInvoice"
                        >PAY</v-btn
                      ></v-col
                    >
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <confirm-model
          v-bind="confirmModel"
          @confirm="confirmActions"
          @close="confirmModel.id = null"
        />
      </v-card>
    </v-dialog>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    />
    <CustomerProductWallet
      v-bind="walletModel"
      :totalAmount="payInvoiceData.total"
      @refreshWallet="refreshWallet"
      @updateProductWalletRedeem="updateProductWalletRedeem"
      @close="
        (walletModel.products = []),
          (walletModel.showModal = false),
          (walletModel.customer_id = null),
          (walletModel.invoice_id = null)
      "
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PaymentMethodNew from "@/components/Order/PaymentMethodNew.vue";
import CustomerProductWallet from "@/components/Order/CustomerProductWallet.vue";
export default {
  components: {
    PaymentMethodNew,
    CustomerProductWallet,
  },
  props: {
    amount: { type: Number, default: null },
    invoiceId: { type: Number, default: null },
    cartItems: { type: Array, default: () => [] },
    posPaymentDialog: { type: Boolean, default: false },
    customerInfo: { type: Object, default: null },
  },
  watch: {
    posPaymentDialog: {
      immediate: true,
      handler(val) {
        if (val) {
          this.payInvoiceData.discount = null;
          this.payInvoiceData.customerInfo = this.customerInfo;
          this.payInvoiceData.tax = this.calculateCartSummary.tax;
          this.payInvoiceData.price = this.calculateCartSummary.price;
          this.payInvoiceData.total = this.calculateCartSummary.total;
          this.discount = null;
          this.discountAmount = null;
          this.discountPercentage = null;
          this.loadProductWallet();
          console.log("this.customerInfo");
          console.log(this.customerInfo);
        } else {
          console.log(val);
          this.payInvoiceData = {};
        }
      },
    },
  },
  data() {
    return {
      panel1Open: [0],
      confirmInputModel: {},
      discountAmount: null,
      discountPercentage: null,
      prevdiscountAmount: null,
      paymentNote: null,
      payInvoiceData: {},
      confirmModel: {},
      dataBeforeDiscount: {},
      discount: {},
      walletModel: {
        showModal: false,
        customer_id: null,
        products: [],
        invoice_id: null,
      },
      loadingProducts: false,
      isEditing: true,
      is_wallet_redeem: false,
      payer: null,
      wallet: { cash: 0, products: 0 },
      wallet_redeem_amount: 0,
      products_redeemed: {},
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      invoice: "getInvoiceDetails",
    }),
    payments() {
      return this.$store.getters.getInvoicePayments;
    },
    paymentTotal() {
      return this.$store.getters.getInvoicePaymentTotal;
    },
    calculateCartSummary() {
      const cartSummary = {
        tax: 0.0,
        subtotal: 0.0,
        total: 0.0,
      };
      if (this.cartItems) {
        let tax = 0;
        let subtotal = 0;
        let total = 0;
        this.cartItems.forEach((item) => {
          tax += item.tax_amount * item.quantity;
          subtotal += item.price * item.quantity;
          total += item.price * item.quantity + item.tax_amount * item.quantity;
        });
        cartSummary.tax = tax;
        cartSummary.price = subtotal;
        cartSummary.total = total;
      }
      return cartSummary;
    },
    venueServiceId() {
      const filteredService = this.$store.getters.getVenueServices.data.find(
        (item) => item.name.toLowerCase() === "pos"
      );
      if (filteredService) {
        return filteredService.id;
      } else {
        return null;
      }
    },
  },
  methods: {
    refreshFormValidation() {
      this.$refs.form.resetValidation();
    },
    refreshWallet() {
      this.$emit("refreshWallet");
    },
    loadProductWallet() {
      this.wallet.products = 0;
      if (this.customerInfo && this.customerInfo.customer_id) {
        this.loadingProducts = true;
        let data = null;
        data = {
          invoice_id: null,
          customer_id: this.customerInfo.customer_id,
        };

        this.$http
          .post(`venues/customers/products-wallet/get-customer-all`, data)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.hideLoader();
              let prod = response.data.products;
              let cash = response.data.cash;
              this.wallet.products = prod;
              this.wallet.cash = cash;
              this.loadingProducts = false;
              this.payInvoiceData.wallet_redeem_amount = 0;
              this.products_redeemed = {};
              let data = {
                id: this.customerInfo.customer_id,
                name: this.customerInfo.name,
                wallet: cash,
                mobile: this.customerInfo.mobile,
                email: this.customerInfo.email,
              };
              this.$store.dispatch("setCustomer", data);
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
            this.loadingProducts = false;
          });
      }
    },
    showWalletProducts() {
      if (this.discountAmount || this.discountPercentage) {
        this.showError("Promotion already applied, cannot redeem products");
        return;
      }
      if (this.customerInfo && this.customerInfo.customer_id) {
        this.payInvoiceData.wallet_redeem_amount = 0;
        this.showLoader("Loading...");
        let data = null;
        data = {
          invoice_id: this.invoiceId,
          customer_id: this.customerInfo.customer_id,
        };
        this.$http
          .post(
            `venues/customers/products-wallet/get-customer-invoice-all`,
            data
          )
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.hideLoader();
              let data = response.data.data;
              this.walletModel.products = [];
              data.forEach((d) => {
                this.walletModel.products.push({
                  id: d.id,
                  name: d.product.name,
                  product_id: d.product.id,
                  quantity: d.quantity,
                  type: d.product.product_type,
                  status: d.status_id,
                });
              });
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
        this.walletModel.invoice_id = 0;
        this.walletModel.customer_id = this.customerInfo.customer_id;
        this.walletModel.showModal = true;
      }
    },
    updateProductWalletRedeem(amount, products_redeemed) {
      this.products_redeemed = products_redeemed;
      this.updateRedeemAmount(amount);
    },
    updateRedeemAmount(amount) {
      this.payInvoiceData.wallet_redeem_amount = amount;
    },
    removePaymentMethod(data) {
      this.confirmModel = {
        id: data.index,
        title: "Do you want to remove this Payment Method?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "remove_payment_method",
        data: { id: data.id, index: data.index },
      };
    },
    confirmActions(data) {
      if (data.type == "remove_payment_method") {
        setTimeout(() => {
          this.$store.commit("removePaymentMethod", data.data.index);
        });
      } else if (data.type == "cancel") {
        this.$emit("cancel");
      }
      this.confirmModel.id = null;
    },
    validate() {
      this.$refs.form.$el.removeAttribute("novalidate");
      this.$refs.form.validate();
      this.$refs.form.$el.setAttribute("validate", "validate");
    },

    specialDiscountChange(type) {
      this.resetPaymentMethod();
      if (type == "percentage") {
        this.discountAmount = null;
        if (this.discountPercentage <= 100) {
          this.addSpecialDiscountPercentage(this.discountPercentage);
        }
      } else if (type == "amount") {
        this.discountPercentage = null;
        //Regex to limit input to 2 decimal places

        let stringValue = this.discountAmount.toString();
        let regex = /^[0-9]*(\.[0-9]{0,2})?$/;
        if (!stringValue.match(regex)) {
          this.discountAmount = parseFloat(this.prevdiscountAmount);
        }
        this.prevdiscountAmount = parseFloat(this.discountAmount);
        if (this.discountAmount < this.calculateCartSummary.total) {
          this.addSpecialDiscount(this.discountAmount);
        } else {
          this.showError("Payment amount not matching with order amount!");
          this.discountAmount = null;
          this.addSpecialDiscount(0);
        }
      }
    },

    payInvoice() {
      this.showLoader("Payment processing");
      let data = {};
      data = {
        venue_service_id: this.venueServiceId,
        payments: this.payments,
        customer:
          this.customerInfo.customer_type == "walk-in"
            ? null
            : this.customerInfo,
        products: this.cartItems,
      };
      if (this.payments.find((x) => x.payment_method == "Complementary")) {
        data.isComplementary = true;
      }
      if (this.paymentNote) {
        data.notes = this.paymentNote;
      }
      let totalAmount = this.calculateCartSummary.total;
      if (this.discount) {
        data.discount = parseFloat(this.payInvoiceData.total);
        totalAmount = parseFloat(this.payInvoiceData.total);
      }
      if (!this.$refs.form.validate()) {
        this.hideLoader();
        this.showError("Please fill all fields");
        return;
      }
      if (
        (
          parseFloat(this.paymentTotal) > totalAmount ||
          parseFloat(this.paymentTotal) < totalAmount) && !data.isComplementary
      ) {
        this.hideLoader();
        this.showError("Payment amount not matching with order amount!");
        this.resetPaymentMethod();
        return 0;
      }
      let postData = {formData : btoa(JSON.stringify(data))}
      this.$http
        .post("venues/pos/checkout", postData)
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.resetPaymentMethod();
            this.showSuccess("Payment success");
            this.hideLoader();
            let data = response.data.data;
            this.$emit("payed", data.id);
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
          this.resetPaymentMethod();
        });
    },
    addSpecialDiscount(amount) {
      this.discount = null;
      this.payInvoiceData.discount = false;
      this.payInvoiceData.price = this.calculateCartSummary.price;
      this.payInvoiceData.tax = this.calculateCartSummary.tax;
      this.payInvoiceData.total = this.calculateCartSummary.total;
      this.payInvoiceData.promotion_name = "";

      if (amount && amount <= this.calculateCartSummary.total) {
        let discountAmount = this.calculateCartSummary.total - amount;
        let discount = (discountAmount / this.calculateCartSummary.total) * 100;
        let taxPercentage = (this.calculateCartSummary.tax / 100) * discount;

        if (this.discount) {
          this.discount.actual_price = this.calculateCartSummary.price.toFixed(
            2
          );
          this.discount.actual_tax = this.calculateCartSummary.tax.toFixed(2);
          this.discount.actual_total = this.calculateCartSummary.total.toFixed(
            2
          );
        } else {
          this.discount = {
            actual_price: this.calculateCartSummary.price.toFixed(2),
            actual_tax: this.calculateCartSummary.tax.toFixed(2),
            actual_total: this.calculateCartSummary.total.toFixed(2),
          };
        }
        this.payInvoiceData.discount = true;
        this.payInvoiceData.price = (
          this.calculateCartSummary.price -
          (this.calculateCartSummary.price * discount) / 100
        ).toFixed(2);
        this.payInvoiceData.tax = this.calculateCartSummary.tax - taxPercentage;
        this.payInvoiceData.total = parseFloat(parseFloat(amount).toFixed(2));
        this.payInvoiceData.promotion_name = "Special Discount";
      }
    },
    addSpecialDiscountPercentage(percentage) {
      this.discount = null;
      this.payInvoiceData.discount = false;
      this.payInvoiceData.price = this.calculateCartSummary.price;
      this.payInvoiceData.tax = this.calculateCartSummary.tax;
      this.payInvoiceData.total = this.calculateCartSummary.total;
      this.payInvoiceData.promotion_name = "";

      if (percentage != 0) {
        let amount =
          this.payInvoiceData.total -
          (this.payInvoiceData.total / 100) * percentage;
        let taxPercentage = (this.payInvoiceData.tax / 100) * percentage;

        if (this.discount) {
          this.discount.actual_price = this.calculateCartSummary.price.toFixed(
            2
          );
          this.discount.actual_tax = this.calculateCartSummary.tax.toFixed(2);
          this.discount.actual_total = this.calculateCartSummary.total.toFixed(
            2
          );
        } else {
          this.discount = {
            actual_price: this.calculateCartSummary.price.toFixed(2),
            actual_tax: this.calculateCartSummary.tax.toFixed(2),
            actual_total: this.calculateCartSummary.total.toFixed(2),
          };
        }

        this.payInvoiceData.discount = true;
        this.payInvoiceData.price = (
          this.payInvoiceData.price -
          (this.payInvoiceData.price * percentage) / 100
        ).toFixed(2);
        this.payInvoiceData.tax -= taxPercentage;
        this.payInvoiceData.total = parseFloat(amount.toFixed(2));
        this.payInvoiceData.promotion_name = "Special Discount";
      }
    },
    resetPaymentMethod() {
      this.$store
        .dispatch("resetPaymentMethod")
        .then(() => {
          this.$refs.form.resetValidation();
        })
        .catch((error) => {
          this.errorChecker(error);
        });
      this.$store.dispatch("setCustomer", null);
      this.$forceUpdate();
    },
    close() {
      this.resetPaymentMethod();
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.heading-title {
  font-size: 16px;
}

.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-700 {
  font-weight: 700;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.text_line {
  border-bottom: 3px solid rgb(0, 176, 175);
}
.order-summary {
  background-color: rgba(17, 42, 69, 0.03);
}
.placeholder-small ::v-deep label {
  font-weight: 400;
  font-size: 12px;
}
.placeholder-small ::v-deep .v-text-field__prefix,
.placeholder-small ::v-deep .v-text-field__suffix {
  font-size: 14px;
}
.order-summary .v-expansion-panel.tab-seprate {
  margin-top: 12px;
}
.order-summary button.v-expansion-panel-header.tab-heading {
  color: #112a45;
  font-weight: 600;
  font-size: 12px;
  padding: 0px 12px;
  min-height: 44px;
}
.order-summary .v-list-item__title.product-name {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #112a45;
}
.order-summary .v-expansion-panel-content.tab-content .v-list-item {
  padding: 0px;
  min-height: 44px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.order-summary .v-expansion-panel-content.tab-content .v-list-item:last-child {
  border-bottom: 0px;
}
.order-summary .v-list-item__content {
  max-height: 65px;
}

.order-summary
  .v-expansion-panel-content.tab-content
  ::v-deep
  .v-expansion-panel-content__wrap {
  padding: 0px 12px;
}
.order-summary .smaller-col {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
}
.order-summary .cart-text-bottom {
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
}
</style>
