<template>
    <div class="product-box">
        <div class="product-image"  @click="addToCart">
            <img :src="product_image" />
        </div>
        <div class="product-meta">
            <div class="product-title">{{ name }}
                <span class="display-block" v-if="enable_retail_inventory">
                    Remaining Stock: {{ selectedVariant?selectedVariant.stock_level: stock_level}}
                </span>
            </div>
            <div class="product-price main-color">
                <span class="price">{{ (selectedVariant? selectedVariant.total_price:total_price) | toCurrency }}</span>
                <span class="p-variant" v-if="variants && variants.length > 0">
                    <v-select
                        v-model="selectedVariant"
                        :items="variants"
                        item-text="attribute_name"
                        item-value="id"
                        label="Select"
                        persistent-hint
                        return-object
                        single-line
                        hide-details="auto"
                        dense
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        required
                        :rules="[(v) => !!v || 'Product variant is required']"
                    ></v-select>
                </span>
                
            </div>
        </div>
    </div>
</template>
<script>
export default{
    name: 'PosProductItem',
    props: {
        id: { type: Number, default: null },
        name: { type: String, default: "" },
        image: { type: String, default: "" },
        product_image: { type: String, default: "" },
        total_price: { type: Number, default: 0 },
        variants:{ type: Array, default: () => [],},
        stock_level:{ type: Number, default: 0 },
        enable_retail_inventory:{ type: Number, default: 0 },
        refreshProductItem: { type: Number, default: 0},
    },
    mounted() {
        this.selectedVariant = this.variants.length?this.variants[0]:null;
    },
    watch: {
        refreshProductItem: {
            immediate: true,
            handler() {
                this.selectedVariant = null;
                if(this.variants && this.variants.length){
                    this.selectedVariant = {...this.variants[0]};

                }
            },
        },
    },
    data() {
        return {
            selectedVariant: null,
            variant_stock_level: 0,
        }
    },

    methods: {
        addToCart() {
            if (this.selectedVariant && this.selectedVariant.id) {
                this.$emit("addToCart", { product_id: this.id,vp_id:this.selectedVariant.id });
            } else {
                this.$emit("addToCart", { product_id: this.id,vp_id: null });
            }
        },
       
    },
}
</script>