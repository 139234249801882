<template>
    <div>
        <v-dialog v-model="customerDialog" width="480" @input="closeEdit" class="pos-customer-dialog" persistent>
            <v-form ref="form" v-model="valid" class="pos-customer-form">
                <v-card-actions class="pos-cf-bottom-action">
                    <v-row>
                        <v-col md="9">
                            <div class="pos-cf-action-heading">Customer Details</div>
                        </v-col>
                         <v-col md="3" style="text-align:right;">
                             <v-btn
                              color="darken-1"
                              class="ma-2 text"
                              text
                              @click="closeEdit"
                              >X</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
                <v-card>
                <v-card-text class="pos-cf-body">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                              <v-mobile-search
                                label="Mobile No*"
                                outlined
                                :selected="customerDetails.mobile"
                                @updateCustomer="setCustomerData"
                                readonly
                                v-model="customerDetails.search"
                              ></v-mobile-search>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" class="pt-2 pb-2">
                              <input type="hidden" v-model="customerDetails.id" />
                              <v-name-search
                                label="Name*"
                                outlined
                                :selected="customerDetails.name"
                                :mobile="customerDetails.mobile"
                                :email="customerDetails.email"
                                @updateCustomer="setCustomerData"
                                readonly
                                v-model="customerDetails.nameSearch"
                              >
                              </v-name-search>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                              <v-text-field
                                outlined
                                :readonly="customerDetails.customer_id != null"
                                background-color="#fff"
                                v-model="customerDetails.email"
                                label="Email *"
                                placeholder="Email"
                                required
                              ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions class="pos-cf-bottom-action">
                    <v-btn
                      color="darken-1"
                      class="ma-2 text"
                      text
                       @click="skipCustomerDetails"
                      >Skip</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color=" darken-1"
                      class="ma-2 white--text blue-color"
                      @click="saveCustomer"
                      text
                    >Next</v-btn>
                </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
export default {
  props: {
    userID: { type: Number, default: null },
    type: { type: String, default: "details" },
    customerDialog: { type: Boolean, default: false },
    customerInfo: { type: Object },
  },
  data() {
    return {
      valid: false,
      refreshCountry: false,
    };
  },
  mounted() {
   
  },
  computed: {
    countries() {
      return this.$store.getters.getCountries.data;
    },
    customerDetails() {
        if (this.customerInfo !== "") {
          return this.customerInfo;
        } else {
          return "";
        }
    },
  },
  methods: {
    skipCustomerDetails() {
        this.$emit("skipCustomerDetails");
        this.$refs.form.resetValidation();
    },
    saveCustomer() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      } else {
          this.$emit('goNext', true);
      }
    },
    closeEdit() {
        this.$emit("close");
    },
    setCustomerData(data) {
      this.$emit("setCustomerData", data);
    },
  },
};
</script>

<style scoped>
.pos-cf-action-heading {
    color: var(--dark-blue, #112A46);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 18px;
}

form.v-form.pos-customer-form {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
}

button.ma-2.text.v-btn.v-btn--text.theme--light.v-size--default.darken-1--text.pos-cf-skip-btn {
    color: #A7A7A7;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

button.ma-2.white--text.blue-color.v-btn.v-btn--text.theme--light.v-size--default.darken-1--text.pos-cf-btn-next {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    width: 75px;
    height: 40px;
}
/deep/ .v-dialog {
    overflow-y: unset;
}
</style>
