<template>
  <v-container fluid no-gutters>

    <div class="md-card md-theme-default mt-8 shadow rounded-5">
      <div class="md-card-content md-card-table">
        <div>
          <div class="d-flex justify-space-between align-center ">
            <SvgIcon class="text-2xl font-semibold" text="Invoice Details" >
            </SvgIcon>
            <v-spacer/>
            <v-select
                v-model="perPage"
                :items="[10, 15, 25, 50]"
                :menu-props="{ bottom: true, offsetY: true }"
                class="q-autocomplete shadow-0 m-r-3 mt-2"
                hide-details
                outlined
                @change="searchData"
                style="max-width: 120px"
                placeholder="Per Page"
                dense
            ></v-select>
            <v-autocomplete
                v-model="columns"
                :items="columnData"
                class="q-autocomplete  shadow-0 m-r-3 mt-2"
                hide-details
                item-text="name"
                item-value="value"
                multiple
                dense
                outlined
                placeholder="Table Filter"
                style="max-width: 200px"
            >
              <template
                  v-if="columns.length === columnData.length"
                  v-slot:selection="{ index }"
              >
                <span v-if="index === 0">All Fields</span>
              </template>
              <template v-else v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.name }}</span>
                <span v-if="index === 1">, ...</span>
              </template>
              <template v-slot:prepend-inner >
                <SvgIcon >
                  <template v-slot:icon>
                    <FilterIcon/>
                  </template>
                </SvgIcon>
              </template>
            </v-autocomplete>
            <v-autocomplete
                v-model="searchParams.time_intervel"
                :items="timeDuration"
                class="q-autocomplete   shadow-0 m-r-3 mt-2"
                hide-details
                dense
                item-text="title"
                item-value="name"
                outlined
                placeholder="Time Period"
                style="max-width: 200px"
                @change="changeFxn"
                height="20"

            >

            </v-autocomplete>


            <div v-if="flag" class="d-flex align-center gap-x-4 mt-2">
              <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  filled
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="date1Formatted"
                      class="shadow-0 q-text-field"
                      dense
                      flat
                      hide-details
                      outlined
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="searchParams.start_date"
                    @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
              <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="date2Formatted"
                      class="shadow-0 q-text-field"
                      dense
                      flat
                      hide-details
                      outlined
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="searchParams.end_date"
                    @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
              <v-btn class="mr-2 bg-blue text-white" height="40"  elevation="0" @click="searchData">
                Go
              </v-btn>
            </div>
            <v-btn
                v-if="exportPermission || checkPermission"
                @click="salesReportExcelDownload"
                class="export-button mt-2"
                elevation="0"
                height="40"
            >
              <SvgIcon text="Export" >
                <template v-slot:icon>
                  <ExportIcon/>
                </template>
              </SvgIcon>
            </v-btn>
          </div>

          <br><br/>
          <div class="table-responsive">
            <table class="table border-collapse">
              <thead class="">
              <tr class="opacity-70 tr-neon tr-rounded ">
                <th
                    v-if="checkTableColumnVisibility('timestamp')"
                >
                  <div
                      style="min-width: 120px"
                  >
                    <div
                        class=" cursor-pointer"
                        @click="sortColumn('id')"
                    >
                      Timestamp
                      <v-icon class="ml-2" color="#000" small>
                        mdi-sort{{
                          orderBy == "id"
                              ? "-" +
                              (orderDir == "ASC"
                                  ? "ascending"
                                  : "descending")
                              : ""
                        }}
                      </v-icon>
                    </div>
                    <div class="search_column mt-1">
                      <date-menu
                          v-model="searchParams.timestamp"
                          @change="getFilterData"
                          :range="true"
                          class-name="q-text-field shadow-0"
                          :flat="true"
                          :dense="true"
                          hide-details="hide-details"
                      >
                      </date-menu>
                    </div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('receipt_no')"
                >
                  <div
                      style="width: 200px"

                  >
                    <div >Receipt No</div>
                    <div class="search_column">
                      <v-text-field
                          solo
                          filled
                          placeholder="Receipt No"
                          clearable
                          v-model="searchParams.receipt_no"
                          @change="getFilterData"
                          class="q-text-field shadow-0"
                          flat
                          dense
                          hide-details="hide-details"
                      ></v-text-field>
                    </div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('invoice_no')"
                >
                  <div
                      style="width: 200px"

                  >
                    <div >Invoice No</div>
                    <div class="search_column">
                      <v-text-field
                          solo
                          filled
                          placeholder="Invoice No"
                          clearable
                          v-model="searchParams.invoice_no"
                          @change="getFilterData"
                          class="q-text-field shadow-0"
                          flat
                          dense
                          hide-details="hide-details"
                      ></v-text-field>
                    </div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('cashier_name')"
                >
                  <div

                  >
                    <div style="min-width: 120px">
                      Cashier Name
                    </div>
                    <div class="search_column">
                      <v-autocomplete
                          solo
                          filled
                          placeholder="Name"
                          v-model="searchParams.cashier_name"
                          item-value="name"
                          item-text="name"
                          :items="cashierNames"
                          :loading="isLoading"
                          :search-input.sync="cashierName"
                          @change="getFilterData"
                          class="q-autocomplete shadow-0"
                          flat
                          dense
                          hide-details="hide-details"
                      ></v-autocomplete>
                    </div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('date')"
                >
                  <div

                  >
                    <div class=" cursor-pointer" style="min-width: 120px">
                      Due Date
                    </div>
                    <div class="search_column mt-1">
                      <date-menu
                          v-model="searchParams.date"
                          @change="getFilterData"
                          :range="true"
                          class-name="q-text-field shadow-0"
                          :flat="true"
                          :dense="true"
                          hide-details="hide-details"
                      >
                      </date-menu>
                    </div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('name')"
                >
                  <div

                  >
                    <div style="min-width: 130px">
                      Customer Name
                    </div>
                    <div class="search_column">
                      <v-autocomplete
                          solo
                          filled
                          placeholder="Name"
                          v-model="searchParams.name"
                          item-value="name"
                          item-text="name"
                          :items="names"
                          :loading="isLoading"
                          :search-input.sync="name"
                          @change="getFilterData"
                          class="q-autocomplete shadow-0"
                          flat
                          dense
                          hide-details="hide-details"
                      ></v-autocomplete>
                    </div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('mobile')"
                >
                  <div

                  >
                    <div >Mobile</div>
                    <div class="search_column">
                      <v-autocomplete
                          solo
                          filled
                          placeholder="Mobile"
                          v-model="searchParams.mobile"
                          item-value="mobile"
                          item-text="mobile"
                          :items="phones"
                          :loading="isLoading"
                          :search-input.sync="phone"
                          @change="getFilterData"
                          class="q-autocomplete shadow-0"
                          flat
                          dense
                          hide-details="hide-details"
                      ></v-autocomplete>
                    </div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('email')"
                >
                  <div

                  >
                    <div >Email</div>
                    <div class="search_column">
                      <v-autocomplete
                          solo
                          filled
                          placeholder="Email"
                          v-model="searchParams.email"
                          item-value="email"
                          item-text="email"
                          :items="emails"
                          :loading="isLoading"
                          :search-input.sync="email"
                          @change="getFilterData"
                          class="q-autocomplete shadow-0"
                          flat
                          dense
                          hide-details="hide-details"
                      ></v-autocomplete>
                    </div>
                  </div>
                </th>

                <th
                    v-if="checkTableColumnVisibility('price')"
                >
                  <div
                      style="min-width: 100px"
                  >
                    <div >Total Price</div>
                    <div class="search_column">
                      <v-menu
                          v-model="priceMenu"
                          :close-on-content-click="false"
                          :nudge-width="50"
                          max-width="150"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              label="Price"
                              solo
                              v-bind="attrs"
                              v-on="on"
                              class="q-text-field shadow-0"
                              flat
                              dense
                              hide-details="hide-details"
                          ></v-text-field>
                        </template>
                        <v-card width="150">
                          <v-card-text>
                            <v-text-field
                                dense
                                v-model="searchParams.from_amount"
                                placeholder="Price From"
                                class="q-text-field shadow-0"
                                flat

                                hide-details="hide-details"
                            ></v-text-field>
                            <v-text-field

                                v-model="searchParams.to_amount"
                                placeholder="Price To"
                                class="q-text-field shadow-0"
                                flat
                                dense
                                hide-details="hide-details"
                            ></v-text-field>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                @click="
                                          () => {
                                            searchParams.from_amount = null;
                                            searchParams.to_amount = null;
                                            priceMenu = false;
                                            searchData();
                                          }
                                        "
                            >Clear</v-btn
                            >
                            <v-btn
                                color="primary"
                                @click="
                                          () => {
                                            priceMenu = false;
                                            searchData();
                                          }
                                        "
                            >Filter</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('method')"
                >
                  <div
                      style="width: 200px"
                  >
                    <div >
                      Payment Method
                    </div>

                    <div class="search_column">
                      <v-select
                          v-model="searchParams.payment_method"
                          placeholder="Payment Methods"
                          :items="
                                    paymentMethods.concat(
                                      paymentMethodFromOnline
                                    )
                                  "
                          item-text="name"
                          item-value="id"
                          multiple
                          solo
                          filled
                          @change="searchData"
                          class="q-autocomplete shadow-0"
                          flat
                          dense
                          hide-details="hide-details"
                      >
                        <template
                            v-if="
                                      searchParams.payment_method.length ==
                                        paymentMethods.length
                                    "
                            v-slot:selection="{ index }"
                        >
                                    <span v-if="index == 0" class="ellipsis"
                                    >All Payment Methods</span
                                    >
                        </template>
                        <template
                            v-else
                            v-slot:selection="{ item, index }"
                        >
                                    <span v-if="index == 0">
                                      {{ item.name }}
                                    </span>
                          <span v-if="index == 1"> , ... </span>
                        </template>

                        <template v-slot:prepend-item>
                          <v-list-item
                              ripple
                              @click="
                                        toggle(
                                          'paymentMethods',
                                          'payment_method',
                                          1
                                        )
                                      "
                          >
                            <v-list-item-action>
                              <v-icon
                                  :color="
                                            searchParams.payment_method.length >
                                            0
                                              ? 'indigo darken-4'
                                              : ''
                                          "
                              >{{
                                  getIcon(
                                      "paymentMethods",
                                      "payment_method",
                                      1
                                  )
                                }}</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                              >All</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </th>
                <!-- Transaction status -->
                <th
                    style="position: relative; min-width: 155px"
                    v-if="checkTableColumnVisibility('transaction')"
                >
                  <div
                      style="width: 200px"
                  >
                    <div >
                      Transaction Status
                    </div>

                    <div class="search_column">
                      <v-select
                          v-model="searchParams.status_id"
                          label="Status"
                          :items="statuses"
                          item-text="name"
                          item-value="id"
                          multiple
                          solo
                          filled
                          @change="searchData"
                          class="q-autocomplete shadow-0"
                          flat
                          dense
                          hide-details="hide-details"
                      >
                        <template
                            v-if="
                                      searchParams.status_id != null &&
                                        searchParams.status_id.length ==
                                          statuses.length
                                    "
                            v-slot:selection="{ index }"
                        >
                                    <span
                                        v-if="index == 0"
                                        class="ellipsis-small"
                                    >All Status</span
                                    >
                        </template>
                        <template
                            v-else
                            v-slot:selection="{ item, index }"
                        >
                                    <span v-if="index == 0">
                                      {{ item.name }}
                                    </span>
                          <span v-if="index == 1"> , ... </span>
                        </template>

                        <template v-slot:prepend-item>
                          <v-list-item
                              ripple
                              @click="
                                        toggle('statuses', 'status_id', 1)
                                      "
                          >
                            <v-list-item-action>
                              <v-icon
                                  :color="
                                            searchParams.status_id != null &&
                                            searchParams.status_id.length > 0
                                              ? 'indigo darken-4'
                                              : ''
                                          "
                              >{{
                                  getIcon("statuses", "status_id", 1)
                                }}</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                              >All</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </th>
                <!-- Invoice status -->
                <th
                    style="position: relative; min-width: 155px"
                    v-if="checkTableColumnVisibility('transaction')"
                >
                  <div

                  >
                    <div >Status</div>

                    <div class="search_column">
                      <v-select
                          v-model="searchParams.invoice_status_id"
                          label="Status"
                          :items="invoiceStatuses"
                          item-text="name"
                          item-value="id"
                          multiple
                          solo
                          filled
                          @change="searchData"
                          class="q-autocomplete shadow-0"
                          flat
                          dense
                          hide-details="hide-details"
                      >
                        <template
                            v-if="
                                      searchParams.invoice_status_id != null &&
                                        searchParams.invoice_status_id.length ==
                                          statuses.length
                                    "
                            v-slot:selection="{ index }"
                        >
                                    <span
                                        v-if="index == 0"
                                        class="ellipsis-small"
                                    >All Status</span
                                    >
                        </template>
                        <template
                            v-else
                            v-slot:selection="{ item, index }"
                        >
                                    <span v-if="index == 0">
                                      {{ item.name }}
                                    </span>
                          <span v-if="index == 1"> , ... </span>
                        </template>

                        <template v-slot:prepend-item>
                          <v-list-item
                              ripple
                              @click="
                                        toggle(
                                          'invoiceStatuses',
                                          'invoice_status_id',
                                          1
                                        )
                                      "
                          >
                            <v-list-item-action>
                              <v-icon
                                  :color="
                                            searchParams.invoice_status_id !=
                                              null &&
                                            searchParams.invoice_status_id
                                              .length > 0
                                              ? 'indigo darken-4'
                                              : ''
                                          "
                              >{{
                                  getIcon(
                                      "invoiceStatuses",
                                      "invoice_status_id",
                                      1
                                  )
                                }}</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                              >All</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </th>
                <th
                    style="position: relative; min-width: 155px"
                    v-if="checkTableColumnVisibility('transaction')"
                >
                  <div
                  >
                    <div >
                      Action
                    </div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('invoice_notes')"
                >
                  <div

                  >
                    <div >Notes</div>
                  </div>
                </th>
              </tr>
              </thead>

              <tbody>
              <tr
                  v-for="(data, index) in logDatas"
                  :key="index"
              >
                <td
                    v-if="checkTableColumnVisibility('timestamp')"
                >
                  <div >
                    {{ data.timestamp | timeStamp }}
                  </div>
                </td>
                <td  v-if="checkTableColumnVisibility('receipt_no')">
                  <div >
                    {{ data.receipt_no }}
                  </div>
                </td>
                <td  v-if="checkTableColumnVisibility('invoice_no')">
                  <div >
                    {{ data.invoice_seq_no }}
                  </div>
                </td>
                <td
                    v-if="checkTableColumnVisibility('cashier_name')"
                >
                  <div >
                    {{ data.cashier_name }}
                  </div>
                </td>
                <td
                    v-if="checkTableColumnVisibility('date')"
                >
                  <div >
                    <div
                        class="user_date"
                        v-if="data.orders.length > 0"
                    >
                      {{ data.orders[0].order_date | dayFormat }}
                    </div>
                  </div>
                </td>
                <td
                    v-if="checkTableColumnVisibility('name')"
                >
                  <div >
                    <router-link
                        v-if="
                                  checkReadPermission(
                                    $modules.clients.customers.id
                                  ) && data.customer_id
                                "
                        :to="`/clients/customers/` + data.customer_id"
                    >{{ data.first_name }}
                      {{ data.last_name }}</router-link
                    >

                    <span v-else-if="data.customer_id"
                    >{{ data.first_name }}
                                {{ data.last_name || "" }}</span
                    >
                    <span v-else>NA</span>
                  </div>
                </td>

                <td
                    v-if="checkTableColumnVisibility('mobile')"
                >
                  <div >
                    {{ data.mobile || "NA" }}
                  </div>
                </td>
                <td
                    v-if="checkTableColumnVisibility('email')"
                >
                  <div >
                    {{ data.email || "NA" }}
                  </div>
                </td>
                <td
                    v-if="checkTableColumnVisibility('price')"
                >
                  <div
                      class="text-capitalize"
                  >
                    {{ Number(Math.abs(data.total)) | toCurrency }}
                  </div>
                </td>
                <td
                    v-if="checkTableColumnVisibility('method')"
                >
                  <div
                      class="text-capitalize"
                  >
                    {{
                      data.status != "Refund"
                          ? data.payment_method_name
                          : data.refund_payment_method_name || "NA"
                    }}
                    {{
                      data.card_number
                          ? "- (" +
                          data.card_type +
                          " " +
                          data.card_number.slice(-4) +
                          ")"
                          : ""
                    }}
                  </div>
                </td>
                <td
                    v-if="checkTableColumnVisibility('transaction')"
                >
                  <div
                      class="text-capitalize"
                  >
                    {{ data.status || "NA" }}
                  </div>
                </td>
                <td
                    v-if="checkTableColumnVisibility('transaction')"
                >
                  <div
                      class="text-capitalize"
                  >
                    {{ data.invoice_status || "NA" }}
                  </div>
                </td>
                <td >
                  <v-card-actions>
                    <v-btn
                        class="ma-2 small"
                        @click="openInvoice(data.id, 'view')"
                    >
                                <span
                                    v-if="
                                    data.status == 'Unpaid' ||
                                      (data.paid_at == null &&
                                        !['Rescheduled'].includes(
                                          data.invoice_status
                                        ))
                                  "
                                >Invoice</span
                                >
                      <span v-else-if="data.status == 'Refund'"
                      >Refund Bill</span
                      >
                      <span v-else>Receipt</span>
                    </v-btn>

                    <v-btn
                        v-if="data.status_id == 5"
                        class="ma-2 white--text blue-color small"
                        @click="openPayInvoice(data.id)"
                    >PAY</v-btn
                    >
                  </v-card-actions>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
    <v-row>
      <v-col cols="4"></v-col>
      <v-col cols="4">
        <v-pagination v-model="page" :length="totalPages" class="new-pagination" total-visible="7"></v-pagination>
      </v-col>
      <v-col class="d-flex align-center justify-end" cols="4">
        <div class="d-flex align-center justify-end text-sm gap-x-2">
                <span class="">
                  Result
                </span>
          <div style="width: 80px">
            <v-select
                v-model="perPage"
                :items="[10, 15, 25, 50]"
                :menu-props="{ bottom: true, offsetY: true }"
                class="q-autocomplete text-sm"
                flat
                hide-details
                solo
                @change="searchData"
            ></v-select>
          </div>
          <span>Per Page</span>
        </div>
      </v-col>
    </v-row>







    <customer-model v-bind="userModel" @close="userModel.userID = null" />

    <order-details
      :id="orderId"
      :ids="orderIds"
      :invId="invoiceModel.invoiceId"
      :invIds="invoiceModel.invoiceIds"
      :eInvId="eInvoiceModel.invoiceId"
      :payInvId="paymentModel.invoiceId"
      :creditInvId="creditInvoiceId"
      @openMainOrderDetails="openMainOrderDetails"
      @invoiceReload="invoiceReload"
      @close="closeMainComponent"
    />
  </v-container>
</template>
<script>
import moment from "moment";
import OrderDetails from "@/components/Order/OrderDetails.vue";
import CustomerModel from "@/views/Clients/Customer/CustomerModel";
import { mapGetters } from "vuex";
import FilterIcon from "@/assets/images/misc/filter-search.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
export default {
  props: {
    outletId: {
      type: Number,
      default: null,
    },
    global: {
      type: Boolean,
      default: true,
    },

    exportPermission: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ExportIcon,
    SvgIcon,
    FilterIcon,
   OrderDetails, CustomerModel },
  data() {
    return {
      test: {},
      invoiceData: {},
      eInvoiceData: {},
      ordersData: [],
      invoiceAmount: 0,
      selectedOrders: [],
      items: [],
      sports: [],
      datas: [],
      logDatas: [],
      searchFlag: false,
      date1: moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD"),
      date2: moment().format("YYYY-MM-DD"),
      searchParams: {
        services: [],
        payment_method: [],
        timestamp: [],
        status_id: null,
        start_date: moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      },
      menu1: false,
      menu2: false,
      flag: false,
      getGroundbyID: [],
      timeDuration: [
        { name: "All", title: "All" },
        { name: "week", title: "This Week" },
        { name: "year", title: "This Year" },
        { name: "month", title: "This Month" },
        { name: "custom", title: "Custom Duration" },
      ],
      orderBy: "id",
      orderDir: "DESC",
      status_id: null,
      logClass: false,
      invoiceClass: true,
      creditClass: false,
      refundClass: false,
      cancellationClass: false,
      page: 1,
      totalPages: 1,
      perPage: null,
      fieldData: {},
      btnShow: false,
      receiptData: { id: null },
      ticketData: { id: null },
      product_names: [],
      product_name: null,
      names: [],
      name: null,
      cashierNames: [],
      cashierName: null,
      country_id: null,
      payment_method_id: null,
      phones: [],
      phone: null,
      emails: [],
      email: null,
      isLoading: false,
      datemenu: false,
      duemenu: false,
      AgeRange: ["All", "18-25", "26-30", "31-35"],
      Age: [],
      toAge: null,
      fromAge: null,
      columnData: [],
      columnData_list: [
        { name: "Timestamp", value: "timestamp" },
        { name: "Invoice No", value: "invoice_no" },
        { name: "Original Transaction No", value: "receipt_no" },
        { name: "Cashier name", value: "cashier_name" },
        { name: "Due Date", value: "date" },
        { name: "Customer Type", value: "customer_type" },
        { name: "Customer Name", value: "name" },
        { name: "Customer Mobile", value: "mobile" },
        { name: "Customer Email", value: "email" },
        { name: "Price", value: "price" },
        { name: "Payment Method", value: "method" },
        { name: "Transation Status", value: "transaction" },
        { name: "Notes", value: "invoice_notes" },
      ],
      columns: [],
      columns_list: [
        "timestamp",
        "invoice_no",
        "receipt_no",
        "cashier_name",
        "date",
        "name",
        "price",
        "method",
        "status",
        "transaction",
      ],
      //  paymentMethod: ["Cash", "Card", "Voucher", "Credit", "Complementary"],
      booking_details: {},
      priceMenu: false,
      orderId: null,
      creditInvoiceId: null,
      checkPermission: false,

      userModel: { userID: null, type: "details" },
      invoiceModel: {
        invoiceId: null,
        invoiceIds: [],
        type: "details",
        orderIds: [],
      },
      eInvoiceModel: { invoiceId: null, type: "details", orderIds: [] },
      olModel: { invoiceId: null, type: "details", orderIds: [] },
      paymentModel: { invoiceId: null, type: "details", orderIds: [] },

      showParticipants: false,
      participant: {},
      refresh: false,
      paymentMethodFromOnline: [
        {
          id: 15,
          name: "Online",
          status_id: 1,
          type: "online",
        },
        {
          id: 16,
          name: "Krews",
          status_id: 1,
          type: "online",
        },
      ],
      orderIds: null,
    };
  },
  mounted() {
    if (this.$router.currentRoute.name == "Invoices") {
      this.status_id = [4, 5, 8, 17, 21, 22];
      this.invoiceClass = true;
      this.creditClass = false;
      this.refundClass = false;
      this.cancellationClass = false;
      this.columns = [];
      this.columns = this.columns_list.filter((e) => e !== "original_trans_no");
      this.columns.push("schedule");
      this.columnData = this.columnData_list.filter(
        (e) => e.value !== "original_trans_no"
      );
      this.checkPermission = this.checkExportPermission(
        this.$modules.sales.logs.slug
      );
    } else if (this.$router.currentRoute.name == "Credits") {
      this.status_id = 7;
      this.creditClass = true;
      this.invoiceClass = false;
      this.refundClass = false;
      this.cancellationClass = false;
      this.columns = [];
      this.columns = this.columns_list.filter((e) => e !== "reason");
      this.columns.push("schedule");
      this.columnData = this.columnData_list.filter(
        (e) => e.value !== "reason"
      );
      this.checkPermission = this.checkExportPermission(
        this.$modules.sales.credits.slug
      );
    } else if (this.$router.currentRoute.name == "Refunds") {
      this.refundClass = true;
      this.invoiceClass = false;
      this.creditClass = false;
      this.cancellationClass = false;
      this.status_id = 8;
      this.checkPermission = this.checkExportPermission(
        this.$modules.sales.refunds.slug
      );
      this.columns = this.columns_list.filter((e) => e !== "schedule");
      this.columns.push("reason");
      this.columnData = this.columnData_list;
    } else if (this.$router.currentRoute.name == "Cancellations") {
      this.cancellationClass = true;
      this.invoiceClass = false;
      this.creditClass = false;
      this.refundClass = false;
      this.status_id = 13;
      this.searchData();
      this.checkPermission = this.checkExportPermission(
        this.$modules.sales.logs.slug
      );
      this.columns = this.columns_list.filter(
        (e) =>
          e === "timestamp" ||
          e === "bill_no" ||
          e === "invoice_no" ||
          e !== "original_trans_no" ||
          e === "cashier_name" ||
          e === "date" ||
          e === "name" ||
          e === "type" ||
          e === "service" ||
          e === "price" ||
          e === "status"
      );

      this.columnData = this.columnData_list.filter(
        (e) =>
          e.value === "timestamp" ||
          e.value === "bill_no" ||
          e.value === "invoice_no" ||
          e.value !== "original_trans_no" ||
          e.value === "cashier_name" ||
          e.value === "date" ||
          e.value === "name" ||
          e.value === "type" ||
          e.value === "service" ||
          e.value === "price" ||
          e === "status"
      );
    } else {
      this.columns = [];
      this.columns = this.columns_list.filter((e) => e !== "original_trans_no");
      this.columns.push("schedule");
      this.columnData = [];
      this.columnData = this.columnData_list.filter(
        (e) => e.value !== "original_trans_no"
      );
    }

    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
    if (this.$store.getters.getPaymentMethods.status == false) {
      this.$store.dispatch("loadPaymentMethods", "normal");
    }
    if (this.$store.getters.getStatuses.status == false) {
      this.$store.dispatch("loadStatuses").then(() => {});
    } else {
      this.searchParams.status_id = [];
      this.statuses.forEach((element) => {
        if (element.id) {
          this.searchParams.status_id.push(element.id);
        }
      });
    }

    if (this.$store.getters.getOrderStatuses.status == false) {
      this.$store.dispatch("loadOrderStatuses").then(() => {
        this.searchParams.invoice_status_id = [];
        this.searchData();
      });
    } else {
      this.searchParams.invoice_status_id = [];
      this.searchData();
    }

    setTimeout(() => {
      this.btnShow = true;
    }, 10);

    if (this.$store.getters.getCardTypes.status == false) {
      this.$store.dispatch("loadCardTypes").then((response) => {
        response.forEach((el) => {
          this.paymentMethodFromOnline.push({
            id: el.id.toString(),
            name: "Card" + " ( " + el.name + " )",
            status_id: 1,
            type: "online",
          });
        });
      });
    } else {
      this.$store.getters.getCardTypes.data.forEach((el) => {
        this.paymentMethodFromOnline.push({
          id: el.id.toString(),
          name: "Card" + " ( " + el.name + " )",
          status_id: 1,
          type: "online",
        });
      });
    }
  },
  computed: {
    ...mapGetters({
      getVenueServices: "getVenueServices",
      getSportsCategory: "getSportsCategory",
    }),
    date1Formatted() {
      return moment(this.searchParams.start_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.end_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    countries() {
      return this.$store.getters.getCountries.data;
    },
    venueCode() {
      return this.$store.getters.venueInfo.code.toUpperCase();
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
    paymentMethods() {
      return this.$store.getters.getPaymentMethods.data;
    },
    statuses() {
      return this.$store.getters.getStatuses.data;
    },
    invoiceStatuses() {
      return this.$store.getters.getOrderStatuses.data;
    },
  },
  watch: {
    page() {
      this.searchData();
    },
    name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("name", val);
    },
    cashierName(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("cashier_name", val);
    },
    phone(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("mobile", val);
    },
    email(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("email", val);
    },
    $route(to) {

      if (to.name == "Credits") {
        this.logClass = false;
        this.creditClass = true;
        this.invoiceClass = false;
        this.refundClass = false;
        this.cancellationClass = false;
        this.status_id = 7;
        this.checkPermission = this.checkExportPermission(
          this.$modules.sales.credits.slug
        );
        this.searchData();
        this.columns = this.columns_list.filter((e) => e !== "reason");
        this.columns.push("schedule");
        this.columnData = this.columnData_list.filter(
          (e) => e.value !== "reason"
        );
      } else if (to.name == "Refunds") {
        this.logClass = false;
        this.invoiceClass = false;
        this.creditClass = false;
        this.cancellationClass = false;
        this.refundClass = true;
        this.status_id = 8;
        this.searchData();
        this.checkPermission = this.checkExportPermission(
          this.$modules.sales.refunds.slug
        );
        this.columns = [];
        this.columns = this.columns_list.filter(
          (e) => e !== "original_trans_no"
        );
        this.columns = this.columns_list.filter((e) => e !== "schedule");
        this.columns.push("reason");
        this.columnData = this.columnData_list;
      } else if (to.name == "Cancellations") {
        this.logClass = false;
        this.creditClass = false;
        this.refundClass = false;
        this.cancellationClass = true;
        this.status_id = 13;
        this.searchData();
        this.checkPermission = this.checkExportPermission(
          this.$modules.sales.logs.slug
        );
        this.columns = [];
        this.columns = this.columns_list.filter(
          (e) =>
            e === "timestamp" ||
            e === "bill_no" ||
            e === "invoice_no" ||
            e !== "original_trans_no" ||
            e === "cashier_name" ||
            e === "date" ||
            e === "name" ||
            e === "type" ||
            e === "service" ||
            e === "price" ||
            e === "status"
        );
        this.columnData = [];
        this.columnData = this.columnData_list.filter(
          (e) =>
            e.value === "timestamp" ||
            e.value === "bill_no" ||
            e.value === "invoice_no" ||
            e.value !== "original_trans_no" ||
            e.value === "cashier_name" ||
            e.value === "date" ||
            e.value === "name" ||
            e.value === "type" ||
            e.value === "service" ||
            e.value === "price" ||
            e === "status"
        );
      } else {
        this.logClass = false;
        this.invoiceClass = true;
        this.creditClass = false;
        this.refundClass = false;
        this.cancellationClass = false;
        this.status_id = [4, 5, 8, 17, 21, 22];

        this.checkPermission = this.checkExportPermission(
          this.$modules.sales.logs.slug
        );
        this.searchData();
        this.columns = [];
        this.columns = this.columns_list.filter(
          (e) => e !== "original_trans_no"
        );
        this.columns.push("schedule");
        this.columnData = [];
        this.columnData = this.columnData_list.filter(
          (e) => e.value !== "original_trans_no"
        );
      }
    },
  },
  methods: {
    closeMainComponent() {
      this.orderId = null;
      this.orderIds = null;
      this.invoiceModel.invoiceId = null;
      this.invoiceModel.invoiceIds = [];
      this.eInvoiceModel.invoiceId = null;
      this.paymentModel.invoiceId = null;
      this.creditInvoiceId = null;
      this.searchData();
    },
    openMainOrderDetails(id) {
      this.invoiceModel.invoiceId = null;
      this.eInvoiceModel.invoiceId = null;
      this.olModel.invoiceId = null;
      this.orderId = null;
      this.orderIds = null;
      this.orderId = id;

    },
    showUserModel(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },
    toggle(type, field, searchP) {
      this.$nextTick(() => {
        if (searchP) {
          if (
            this.searchParams[field] != null &&
            this.searchParams[field].length == this[type].length
          ) {
            this.searchParams[field] = [];
          } else {
            this.searchParams[field] = this[type].map((x) => x.id);
          }
        } else {
          if (this[field].length == this[type].length) {
            this[field] = [];
          } else {
            this.searchParams[field] = this[type];
          }
        }
        this.searchData();
      });
      this.$forceUpdate();
    },

    getIcon(type, field, searchP) {
      let icon = "mdi-checkbox-blank-outline";
      if (searchP) {
        if (
          this.searchParams[field] != null &&
          this.searchParams[field].length == this[type].length
        ) {
          icon = "mdi-close-box";
        }
        if (
          this.searchParams[field] != null &&
          this.searchParams[field].length > 0 &&
          this.searchParams[field].length != this[type].length
        )
          icon = "mdi-minus-box";
      } else {
        if (this[field].length == this[type].length) {
          icon = "mdi-close-box";
        }
        if (this[field].length > 0 && this[field].length != this[type].length)
          icon = "mdi-minus-box";
      }
      return icon;
    },

    filterSearch(type, val) {
      this.isLoading = true;
      this.$http
        .get("venues/customers/filters/?field=" + type + "&search=" + val)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.isLoading = false;
            if (type == "name") {
              this.names = response.data.data;
              this.names.unshift(val);
              this.names.unshift("All");
            } else if (type == "mobile") {
              this.phones = response.data.data;
              this.phones.unshift(val);
              this.phones.unshift("All");
            } else if (type == "email") {
              this.emails = response.data.data;
              this.emails.unshift(val);
              this.emails.unshift("All");
            } else if (type == "cashier_name") {
              this.cashierNames = response.data.data;
              this.cashierNames.unshift(val);
              this.cashierNames.unshift("All");
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    getFilterData() {
      this.page = 1;
      this.searchData();
    },
    searchData() {
      this.showLoader("Loading..");
      let url = "";
      if (
        typeof this.$route.name != "undefined" &&
        this.$route.name == "Refunds"
      ) {
        url += "&status=2";
      }

      if (
        this.searchParams.time_intervel != "All" &&
        this.searchParams.time_intervel != null
      ) {
        // this.searchParams.end_date = moment().format("YYYY-MM-DD");
        if (this.searchParams.time_intervel == "custom") {
          this.flag = true;
        } else if (this.searchParams.time_intervel == "week") {
          this.searchParams.start_date = moment()
            .startOf("week")
            .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "month") {
          this.searchParams.start_date = moment()
            .startOf("month")
            .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "year") {
          this.searchParams.start_date = moment()
            .startOf("year")
            .format("YYYY-MM-DD");
        }
        url += "&end_date=" + this.searchParams.end_date;
        url += "&start_date=" + this.searchParams.start_date;
      }
      if (this.searchParams.date == null) {
        this.searchParams.date = null;
      } else if (typeof this.searchParams.date != "undefined") {
        url += "&date=" + this.searchParams.date;
      }
      if (this.searchParams.timestamp == null) {
        delete this.searchParams.timestamp;
      } else if (typeof this.searchParams.timestamp != "undefined") {
        if (this.searchParams.timestamp.length > 1) {
          this.searchParams.timestamp.sort((a, b) => new Date(a) - new Date(b));
        }
        url += "&timestamp=" + this.searchParams.timestamp;
      }
      if (this.searchParams.name == "All") {
        delete this.searchParams.name;
      } else if (this.searchParams.name) {
        url += "&name=" + this.searchParams.name;
      }

      if (this.searchParams.cashier_name == "All") {
        delete this.searchParams.cashier_name;
      } else if (this.searchParams.cashier_name) {
        url += "&cashier_name=" + this.searchParams.cashier_name;
      }

      if (this.searchParams.email == "All") {
        delete this.searchParams.email;
      } else if (this.searchParams.email) {
        url += "&email=" + this.searchParams.email;
      }
      if (this.searchParams.mobile == "All") {
        delete this.searchParams.mobile;
      } else if (this.searchParams.mobile) {
        url += "&mobile=" + this.searchParams.mobile;
      }
      if (this.searchParams.gender == "All") {
        this.searchParams.gender = null;
      } else if (this.searchParams.gender) {
        url += "&gender=" + this.searchParams.gender;
      }
      if (this.searchParams.customer_type == null) {
        delete this.searchParams.customer_type;
      } else if (this.searchParams.customer_type) {
        url += "&customer_type=" + this.searchParams.customer_type;
      }
      if (this.searchParams.country_id == "All") {
        delete this.searchParams.country_id;
      } else if (this.searchParams.country_id) {
        url += "&country_id=" + this.searchParams.country_id;
      }
      if (
        typeof this.searchParams.from_age != "undefined" &&
        typeof this.searchParams.to_age != "undefined"
      ) {
        url +=
          "&from_age=" +
          this.searchParams.from_age +
          "&to_age=" +
          this.searchParams.to_age;
      }
      if (this.searchParams.from_amount) {
        url += "&from_amount=" + this.searchParams.from_amount;
      }
      if (this.searchParams.to_amount) {
        url += "&to_amount=" + this.searchParams.to_amount;
      }
      if (this.searchParams.invoice_no) {
        url += "&invoice_no=" + this.searchParams.invoice_no;
      }
      if (this.searchParams.receipt_no) {
        url += "&receipt_no=" + this.searchParams.receipt_no;
      }

      if (
        this.searchParams.payment_method != "All" &&
        this.searchParams.payment_method != null &&
        this.searchParams.payment_method.length > 0
      ) {
        let cardTypeId = [];
        let paymentMethod = [];
        this.searchParams.payment_method.forEach((ex) => {
          if (typeof ex == "string") {
            cardTypeId.push(Number(ex));
          } else {
            paymentMethod.push(ex);
            if (ex == 6) {
              paymentMethod.push(11);
            }
          }
        });

        if (this.searchParams.payment_method[0] == 11) {
          this.searchParams.payment_method = [];
          paymentMethod = [];
        }
        if (paymentMethod.length) {
          url += `&payment_method=${paymentMethod}`;
        }

        if (cardTypeId.length) {
          url += `&card_type_id=${cardTypeId}`;
        }
      }

      if (
        this.searchParams.invoice_status_id != "All" &&
        this.searchParams.invoice_status_id != null &&
        this.searchParams.invoice_status_id.length > 0
      ) {
        url += `&invoice_status_id=${this.searchParams.invoice_status_id}`;
      } else {
        this.searchParams.invoice_status_id = null;
      }

      if (this.eventId > 0) {
        url += "&event_id=" + this.eventId;
      }

      if (this.promotionId > 0) {
        url += "&promotion_id=" + this.promotionId;
      }

      if (this.refundClass) {
        url += "&status_id=8&refund=true";
      } else if (this.cancellationClass) {
        url += "&status_id=13&invoice_status_id=13,23";
      } else if (
        this.searchParams.status_id != null &&
        this.searchParams.status_id.length > 0 &&
        !this.creditClass
      ) {
        url += "&status_id=" + this.searchParams.status_id;
      } else if (this.status_id != null && !this.creditClass) {
        url += "&status_id=" + this.status_id;
      } else if (this.creditClass) {
        url += "&credit=true";
      }

      this.searchParams.page = this.page;
      this.searchParams.per_page = this.perPage != null ? this.perPage : 10;
      this.searchParams.order_by = this.orderBy;
      this.searchParams.order_by_value = this.orderDir;
      this.$http
        .get(
          "venues/outlets/logs?outlet_id=" +
            this.outletId +
            "&page=" +
            this.page +
            "&per_page=" +
            (this.perPage != null ? this.perPage : 10) +
            "&order_by=" +
            this.orderBy +
            "&sort_order=" +
            this.orderDir +
            url
        )
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            this.logDatas = response.data.data;
            this.totalPages = response.data.total_pages;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    changeFxn() {
      if (this.searchParams.time_intervel == "custom") {
        this.searchParams.start_date = moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD");
        this.searchParams.end_date = moment().format("YYYY-MM-DD");
        this.flag = true;
      } else {
        this.flag = false;
        this.searchData();
      }
    },
    sortColumn(type) {
      if (type == this.orderBy) {
        this.orderDir = this.orderDir == "ASC" ? "DESC" : "ASC";
      } else {
        this.orderDir = "DESC";
      }
      this.orderBy = type;
      this.searchData();
    },
    checkTableColumnVisibility(column) {
      let index = this.columns.findIndex((item) => item == column);
      if (index == -1) {
        return false;
      }
      return true;
    },
    salesReportExcelDownload() {
      this.showLoader("Downloading report");
      let url = "";
      if (
        typeof this.$route.name != "undefined" &&
        this.$route.name == "Refunds"
      ) {
        url += "&status=2";
      }

      if (
        this.searchParams.time_intervel != "All" &&
        this.searchParams.time_intervel != null
      ) {
        this.searchParams.end_date = moment().format("YYYY-MM-DD");
        if (this.searchParams.time_intervel == "custom") {
          this.searchParams.start_date = moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD");
          this.flag = true;
        } else if (this.searchParams.time_intervel == "week") {
          this.searchParams.start_date = moment()
            .startOf("week")
            .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "month") {
          this.searchParams.start_date = moment()
            .startOf("month")
            .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "year") {
          this.searchParams.start_date = moment()
            .startOf("year")
            .format("YYYY-MM-DD");
        }
        url += "&end_date=" + this.searchParams.end_date;
        url += "&start_date=" + this.searchParams.start_date;
      }
      if (this.searchParams.product_type_id != null) {
        url += `&product_type_id=${this.searchParams.product_type_id}`;
      }
      if (
        typeof this.searchParams.reason != "undefined" &&
        this.searchParams.reason.length > 0
      ) {
        url += `&reason=${this.searchParams.reason}`;
      }
      if (
        this.searchParams.services != "All" &&
        this.searchParams.services != null &&
        this.searchParams.services.length > 0
      ) {
        url += `&venue_service_id=${this.searchParams.services}`;
      }
      if (this.searchParams.date == null) {
        this.searchParams.date = null;
      } else if (typeof this.searchParams.date != "undefined") {
        url += "&date=" + this.searchParams.date;
      }
      if (this.searchParams.timestamp == null) {
        delete this.searchParams.timestamp;
      } else if (typeof this.searchParams.timestamp != "undefined") {
        url += "&timestamp=" + this.searchParams.timestamp;
      }
      if (this.searchParams.name == "All") {
        this.searchParams.name = null;
      } else if (typeof this.searchParams.name != "undefined") {
        url += "&name=" + this.searchParams.name;
      }
      if (this.searchParams.email == "All") {
        delete this.searchParams.email;
      } else if (typeof this.searchParams.email != "undefined") {
        url += "&email=" + this.searchParams.email;
      }
      if (this.searchParams.mobile == "All") {
        delete this.searchParams.mobile;
      } else if (typeof this.searchParams.mobile != "undefined") {
        url += "&mobile=" + this.searchParams.mobile;
      }
      if (this.searchParams.gender == "All") {
        delete this.searchParams.gender;
      } else if (typeof this.searchParams.gender != "undefined") {
        url += "&gender=" + this.searchParams.gender;
      }
      if (this.searchParams.customer_type == null) {
        delete this.searchParams.customer_type;
      } else if (typeof this.searchParams.customer_type != "undefined") {
        url += "&customer_type=" + this.searchParams.customer_type;
      }
      if (this.searchParams.country_id == "All") {
        delete this.searchParams.country_id;
      } else if (typeof this.searchParams.country_id != "undefined") {
        url += "&country_id=" + this.searchParams.country_id;
      }

      if (this.searchParams.cashier_name == "All") {
        delete this.searchParams.cashier_name;
      } else if (this.searchParams.cashier_name) {
        url += "&cashier_name=" + this.searchParams.cashier_name;
      }

      if (
        typeof this.searchParams.from_age != "undefined" &&
        typeof this.searchParams.to_age != "undefined"
      ) {
        url +=
          "&from_age=" +
          this.searchParams.from_age +
          "&to_age=" +
          this.searchParams.to_age;
      }
      if (this.searchParams.from_amount != null) {
        url += "&from_amount=" + this.searchParams.from_amount;
      }
      if (this.searchParams.to_amount != null) {
        url += "&to_amount=" + this.searchParams.to_amount;
      }

      if (this.searchParams.invoice_no) {
        url += "&invoice_no=" + this.searchParams.invoice_no;
      }
      if (this.searchParams.receipt_no) {
        url += "&receipt_no=" + this.searchParams.receipt_no;
      }

      if (this.eventId > 0) {
        url += "&event_id=" + this.eventId;
      }

      if (this.promotionId > 0) {
        url += "&promotion_id=" + this.promotionId;
      }
      // if (typeof this.searchParams.payment_method_id != "undefined") {
      //   url += "&payment_method=" + this.searchParams.payment_method_id;
      // }

      if (
        this.searchParams.payment_method != "All" &&
        this.searchParams.payment_method != null &&
        this.searchParams.payment_method.length > 0
      ) {
        let cardTypeId = [];
        let paymentMethod = [];
        this.searchParams.payment_method.forEach((ex) => {
          if (typeof ex == "string") {
            cardTypeId.push(Number(ex));
          } else {
            paymentMethod.push(ex);
            if (ex == 6) {
              paymentMethod.push(11);
            }
          }
        });

        if (this.searchParams.payment_method[0] == 11) {
          this.searchParams.payment_method = [];
          paymentMethod = [];
        }
        if (paymentMethod.length) {
          url += `&payment_method=${paymentMethod}`;
        }

        if (cardTypeId.length) {
          url += `&card_type_id=${cardTypeId}`;
        }
      }

      if (
        this.searchParams.invoice_status_id != "All" &&
        this.searchParams.invoice_status_id != null &&
        this.searchParams.invoice_status_id.length > 0
      ) {
        url += `&invoice_status_id=${this.searchParams.invoice_status_id}`;
      } else {
        this.searchParams.invoice_status_id = null;
      }
      if (this.refundClass) {
        url += "&status_id=8&refund=true";
      } else if (this.cancellationClass) {
        url += "&status_id=13";
      } else if (this.searchParams.status_id != null && !this.creditClass) {
        url += "&status_id=" + this.searchParams.status_id;
      } else if (this.status_id != null && !this.creditClass) {
        url += "&status_id=" + this.status_id;
      } else {
        url += "&credit";
      }
      this.searchParams.page = this.page;
      this.searchParams.per_page = this.perPage != null ? this.perPage : 10;
      this.searchParams.order_by = this.orderBy;
      this.searchParams.order_by_value = this.orderDir;
      this.$http
        .get(
          "venues/outlets/logs/report?outlet_id=" +
            this.outletId +
            "&page=" +
            this.page +
            "&per_page=" +
            (this.perPage != null ? this.perPage : 10) +
            "&order_by=" +
            this.orderBy +
            "&sort_order=" +
            this.orderDir +
            url,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.downloadFile(response, "SalesReport_");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    closeInvoiceModel() {
      this.invoiceModel.invoiceId = null;
      this.eInvoiceModel.invoiceId = null;
      this.olModel.invoiceId = null;
      this.searchData();
    },
    openOlModel(invoice_id) {
      this.invoiceModel.invoiceId = null;
      this.eInvoiceModel.invoiceId = null;
      this.olModel.invoiceId = invoice_id;
    },
    closeOlModel() {
      this.olModel.invoiceId = null;
    },
    payPopup(invoiceId) {
      this.paymentModel.invoiceId = invoiceId;
    },
    closePaymentModel() {
      this.paymentModel.invoiceId = null;
      this.searchData();
    },
    openInvoice(invoice_id, type = "view") {
      if (type == "view") {
        let currentInvoice = this.getCurrentInvoice(invoice_id);
        if (currentInvoice) {
          if (currentInvoice.parent_invoice_ids) {
            this.invoiceModel.invoiceIds = [currentInvoice.id];
            currentInvoice.parent_invoice_ids.split(",").forEach((id) => {
              this.invoiceModel.invoiceIds.push(Number(id));
            });
          } else {
            this.invoiceModel.invoiceIds = [];
            this.invoiceModel.invoiceId = currentInvoice.id;
          }
          this.$forceUpdate();
          // this.invoiceModel.invoiceIds = invoice_id;
        } else {
          this.invoiceModel.invoiceId = invoice_id;
        }
      } else if (type == "edit") {
        this.eInvoiceModel.invoiceId = invoice_id;
      }
    },

    getCurrentInvoice(id) {
      return this.logDatas.find((ele) => ele.id === id);
    },
    openPayInvoice(invoice_id) {
      this.invoiceModel.invoiceId = null;
      this.paymentModel.invoiceId = invoice_id;
    },
    invoiceReload(invoice_id) {
      this.invoiceModel.invoiceId = null;
      this.eInvoiceModel.invoiceId = null;
      this.olModel.invoiceId = null;
      this.openInvoice(invoice_id, "view");
    },
    creditSettle(id) {
      this.creditInvoiceId = id;
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
.book_time_log {
  color: #066a8c;
}
.md-card-header tr th:nth-child(1) {
  text-align: left !important;
}
.search_customer .v-input {
  margin: 0 0px;
}
.headline_ticket {
  background: #000;
  color: #fff;
}
.head {
  text-align: center;
  margin: 0 auto;
}
.headTicket {
  color: gray;
  font-weight: bold;
}
.valueTicket {
  color: #fff;
  border-bottom: 1px solid gray;
}
.sch_btn_class,
.sch_btn_event {
  padding: 0 35px !important;
}
.logTable tbody tr:hover {
  transform: scale(1) !important;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -moz-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  z-index: 1;
}
</style>
