<template>
  <v-container fluid no-gutters>
    <div class="md-card md-theme-default mt-8  shadow  rounded-5">
      <div class="md-card-content md-card-table">
        <div>
          <div class="d-flex justify-space-between align-center  ">
            <SvgIcon class="text-2xl font-semibold" text="Members" >
            </SvgIcon>
            <v-spacer/>
          </div>

          <br><br/>
          <div class="table-responsive">
            <table class="table border-collapse text-center">
              <thead>
              <tr class="opacity-70 tr-neon tr-rounded text-center">
                <th >
                  <div
                  >
                    <div >Name</div>
                    <div class="search_column">
                      <v-autocomplete
                          solo
                          filled
                          placeholder="Name"
                          v-model="searchParams.name"
                          item-value="name"
                          item-text="name"
                          :items="names"
                          :loading="isLoading"
                          :search-input.sync="name"
                          @change="getFilterData"
                          class="q-autocomplete shadow-0"
                          flat
                          dense
                          hide-details="auto"
                      ></v-autocomplete>
                    </div>
                  </div>
                </th>

                <th >
                  <div

                  >
                    <div >Mobile</div>
                    <div class="search_column">
                      <v-autocomplete
                          solo
                          filled
                          placeholder="Mobile"
                          v-model="searchParams.mobile"
                          item-value="mobile"
                          item-text="mobile"
                          :items="phones"
                          :loading="isLoading"
                          :search-input.sync="phone"
                          @change="getFilterData"
                          class="q-autocomplete shadow-0"
                          flat
                          dense
                          hide-details="auto"
                      ></v-autocomplete>
                    </div>
                  </div>
                </th>
                <th >
                  <div

                  >
                    <div >Email</div>
                    <div class="search_column">
                      <v-autocomplete
                          solo
                          filled
                          placeholder="Email"
                          v-model="searchParams.email"
                          item-value="email"
                          item-text="email"
                          :items="emails"
                          :loading="isLoading"
                          :search-input.sync="email"
                          @change="getFilterData"
                          class="q-autocomplete shadow-0"
                          flat
                          dense
                          hide-details="auto"
                      ></v-autocomplete>
                    </div>
                  </div>
                </th>

                <th

                    style="position: relative"
                    v-if="
                            checkReadPermission($modules.outlet.management.slug)
                          "
                >
                  <div

                  >
                    <div >Status</div>
                    <div class="search_column">
                      <v-select
                          solo
                          filled
                          :items="[
                                  { name: 'All', status: null },
                                  { name: 'Active', status: 1 },
                                  { name: 'Inactive', status: 2 },
                                ]"
                          item-text="name"
                          placeholder="Status"
                          v-model="searchParams.status_id"
                          item-value="status"
                          @change="getFilterData()"
                          class="q-autocomplete shadow-0"
                          flat
                          dense
                          hide-details="auto"
                      ></v-select>
                    </div>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="member in customersList"
                  :key="member.id"
                  :style="{
                          'background-color':
                            member.status == 'Expired'
                              ? 'rgb(255 0 0 / 55%)'
                              : member.about_to_expire
                              ? 'rgb(255 0 0 / 20%)'
                              : 'white',
                        }"
              >
                <td >
                  <div class="text-center">
                    <v-btn
                        small
                        color="#00b0af"
                        style="text-transform: none"
                        dark
                        @click="showUserModel(member.customer_id)"
                    >{{ member.first_name }}
                      {{ member.last_name || "" }}</v-btn
                    >
                  </div>
                </td>

                <td class="">
                  <div class=" text-center">
                    {{ member.mobile }}
                  </div>
                </td>
                <td class="">
                  <div class=" text-center">
                    {{ member.email }}
                  </div>
                </td>

                <td
                    class="text-center"
                    v-if="
                            checkReadPermission($modules.outlet.management.slug)
                          "
                >
                  <v-menu left :offset-y="true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          :color="
                                  member.is_customer_blocked == false
                                    ? '#00b0af'
                                    : '#a52a2a'
                                "
                          height="28"
                          dark
                          v-bind="attrs"
                          v-on="on"
                      >{{
                          member.is_customer_blocked == false
                              ? "Active"
                              : "Inactive"
                        }}</v-btn
                      >
                    </template>
                    <v-list>
                      <v-list-item
                          @click="changeOutletCustomerStatus(member)"
                      >
                        <v-list-item-title>
                                  <span>{{
                                      member.is_customer_blocked == false
                                          ? "Deactivate"
                                          : "Activate"
                                    }}</span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
    <v-row>
      <v-col cols="4"></v-col>
      <v-col cols="4">
        <v-pagination v-model="page" :length="totalPages" class="new-pagination" total-visible="7"></v-pagination>
      </v-col>
      <v-col class="d-flex align-center justify-end" cols="4">
        <div class="d-flex align-center justify-end text-sm gap-x-2">
                <span class="">
                  Result
                </span>
          <div style="width: 80px">
            <v-select
                v-model="perPage"
                :items="[10, 15, 25, 50]"
                :menu-props="{ bottom: true, offsetY: true }"
                class="q-autocomplete text-sm"
                flat
                hide-details
                solo
                @change="getFilterData"
            ></v-select>
          </div>
          <span>Per Page</span>
        </div>
      </v-col>
    </v-row>
    <customer-model
        v-bind="userModel"
        @confirm="getFilterData"
        @close="userModel.userID = null"
        @updated="search()"
        :isPermissionCheck="checkWritePermission($modules.outlet.management.slug)"
    />
  </v-container>
</template>

<script>
import moment from "moment";
import CustomerModel from "../../Clients/Customer/CustomerModel.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {
  name: "MembershipDetails",
  props: {
    outletId: { type: Number, default: 0 },
    membershipName: { type: String, default: "" },
    description: { type: String, default: "" },
    durations: { type: Array, default: () => {} },
    packages: { type: Array, default: () => {} },
  },
  components: { CustomerModel ,SvgIcon},
  data() {
    return {
      perPage: 10,
      historyDialoge: false,
      member: {
        customers: [
          {
            first_name: null,
            last_name: null,
            email: null,
            gender: null,
            country_id: null,
            dob: null,
            id_proof: null,
            id_proof_type_id: null,
            is_primary: 1,
            customer_tag: null,
          },
        ],
      },
      venue_service_id: null,
      searchParams: {},
      currentPage: 0,
      pageSize: 10,
      items: [],
      names: [],
      name: null,
      phones: [],
      phone: null,
      emails: [],
      email: null,
      isLoading: false,
      totalPages: 1,
      page: 1,
      date: null,
      menu: false,
      customersList: [],
      userModel: { userID: null, type: "details" },
      historyData: [],
      orderId: null,
      today: moment().format("YYYY-MM-DD"),
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      product: [],
      productType: "Outlet",
      orderBy: "start_date",
      orderDir: "DESC",
    };
  },
  mounted() {
    this.getCustomersList();
  },

  computed: {},

  watch: {
    page() {
      this.getCustomersList();
    },
    name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("name", val);
    },
    phone(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("mobile", val);
    },
    email(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("email", val);
    },
  },

  methods: {
    getFilterUrl() {
      let url = `&page=${this.page}&per_page=${
        this.perPage != null ? this.perPage : 10
      }`;
      if (this.searchParams.name == "All") {
        this.searchParams.name = null;
      } else if (this.searchParams.name != null) {
        url += "&name=" + this.searchParams.name;
      }
      if (this.searchParams.email == "All") {
        delete this.searchParams.email;
      } else if (this.searchParams.email != null) {
        url += "&email=" + this.searchParams.email;
      }
      if (this.searchParams.mobile == "All") {
        delete this.searchParams.mobile;
      } else if (this.searchParams.mobile != null) {
        url += "&mobile=" + this.searchParams.mobile;
      }
      if (this.searchParams.status_id == null) {
        delete this.searchParams.status_id;
      } else if (this.searchParams.status_id != null) {
        url += "&status_id=" + this.searchParams.status_id;
      }
      url += `&order_by=${this.orderBy}&sort_order=${this.orderDir}`;
      return url;
    },
    getCustomersList() {
      let url = this.getFilterUrl();
      this.$http
        .get(`venues/outlets/customers?outlet_id=${this.outletId}${url}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.customersList = response.data.data;
            this.totalPages = response.data.total_pages;
            //this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    filterSearch(type, val) {
      if (val == "All") return;
      this.isLoading = true;
      this.$http
        .get(`venues/memberships/members/filters?field=${type}&search=${val}`)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.isLoading = false;
            if (type == "name") {
              this.names = response.data.data;
              this.names.unshift(val);
              this.names.unshift("All");
            } else if (type == "mobile") {
              this.phones = response.data.data;
              this.phones.unshift(val);
              this.phones.unshift("All");
            } else if (type == "email") {
              this.emails = response.data.data;
              this.emails.unshift(val);
              this.emails.unshift("All");
            } else if (type == "tag") {
              this.tags = response.data.data;
              this.tags.unshift(val);
              this.tags.unshift("All");
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFilterData() {
      this.page = 1;
      this.getCustomersList();
    },

    showUserModel(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },
    exportData() {
      let url = this.getFilterUrl();
      this.$http
        .get(
          `venues/memberships/members/export?membership_id=${this.membershipId}${url}`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          if (response.status == 200) {
            this.downloadFile(response, "Members_Export_");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    sortColumn(type) {
      if (type == this.orderBy) {
        this.orderDir = this.orderDir == "ASC" ? "DESC" : "ASC";
      } else {
        this.orderDir = "DESC";
      }
      this.orderBy = type;
      this.getCustomersList();
    },

    // confirmOutletCustomerStatusChange(data) {
    //   this.confirmModel = {
    //     id: data.id,
    //     title: `Do you want to ${
    //       data.is_customer_blocked == 0 ? "Deactivate" : "Activate"
    //     } this customer?`,
    //     description:
    //       "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
    //     type: "outlet_customer",
    //     data: {
    //       flag: data.flag ? true : false,
    //     },
    //   };
    // },

    changeOutletCustomerStatus(cus) {
      this.$http
        .get(
          `venues/outlets/customers/change-status?customer_id=` +
            cus.customer_id +
            `&outlet_id=` +
            cus.outlet_id
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            //var data = response.data.data;
            this.getCustomersList();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    // confirmActions(data) {
    //   this.changeOutletCustomerStatus(data.customer_id, data.outlet_id);
    //   this.$forceUpdate();
    //   this.confirmModel.id = null;
    // },
    // changeOutletCustomerStatus(customer_id, outlet_id) {
    //   this.$http
    //     .put(
    //       `venues/outlets/customers/change-status?customer_id=` +
    //         customer_id +
    //         `&outlet_id=` +
    //         outlet_id
    //     )
    //     .then((response) => {
    //       if (response.status == 200 && response.data.status == true) {
    //         this.loadOutlets();
    //       }
    //     })
    //     .catch((error) => {
    //       this.errorChecker(error);
    //     });
    // },
  },
};
</script>

<style scoped>
.v-select-list >>> .v-subheader {
  font-size: 0.95rem !important;
  font-weight: 600 !important;
  color: black;
}
tbody tr:hover {
  transform: scale(1) !important;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -moz-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  z-index: 1;
}
.personal-trainer_ic {
  background-image: url("../../../assets/images/nav_icon/personal-trainer-grey.png");
  padding: 10px;
  background-size: contain;
}
.member-card {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.member-card span {
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
}

.member-all {
  border: 2px solid #0000009e;
}

.member-parent {
  border-bottom: 0px;
}

.member-child {
  border-top: 0px;
}
</style>
