<template>
  <v-container fluid>
    <div class="md-card md-theme-default mt-8 shadow  rounded-5">
      <div class="md-card-content md-card-table">
        <div>
          <div class="d-flex justify-space-between align-center ">
            <SvgIcon class="text-2xl font-semibold" text="Outlet Orders" >
            </SvgIcon>
            <v-spacer/>
            <v-select
                v-model="perPage"
                :items="[10, 15, 25, 50]"
                :menu-props="{ bottom: true, offsetY: true }"
                class="q-autocomplete shadow-0 m-r-3 mt-2"
                hide-details
                outlined
                @change="searchData"
                style="max-width: 120px"
                placeholder="Per Page"
                dense
            ></v-select>
            <v-autocomplete
                v-model="searchParams.time_intervel"
                outlined
                placeholder="Time Period"
                @change="changeFxn"
                item-value="name"
                item-text="title"
                :items="timeDuration"
                class="q-autocomplete  shadow-0 mt-2"
                hide-details
                dense
                style="max-width: 200px"
            ></v-autocomplete>
            <div v-if="flag" class="d-flex align-center gap-x-4 mt-2">
              <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      outlined

                      class="q-text-field shadow-0"
                      v-model="date1Formatted"
                      readonly
                      v-on="on"
                      hide-details
                      dense
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="searchParams.start_date"
                    @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
              <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"

              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="date2Formatted"
                      outlined
                      readonly
                      v-on="on"
                      class="q-text-field shadow-0"
                      hide-details
                      dense
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="searchParams.end_date"
                    @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
              <v-btn class="mr-2 bg-blue text-white" height="40"  elevation="0" @click="searchData">
                Go
              </v-btn>
            </div>
            <v-btn
                height="40"
                class="mt-2 shadow-0 ml-2 bg-blue text-white text-capitalize"
                @click="searchData"
                dark
            >
             Refresh
            </v-btn>
          </div>

          <br><br/>
          <div class="table-responsive">
            <table class="table border-collapse ">
              <thead>
              <tr class="opacity-70 tr-neon tr-rounded ">

                <th
                    v-if="checkTableColumnVisibility('timestamp')"
                >
                  <div

                  >
                    <div
                        @click="sortColumn('id')"
                    >
                      Timestamp
                      <v-icon class="ml-2" color="#000" small>
                        mdi-sort{{
                          orderBy == "id"
                              ? "-" +
                              (orderDir == "ASC"
                                  ? "ascending"
                                  : "descending")
                              : ""
                        }}
                      </v-icon>
                    </div>
                    <div class="search_column mt-1">
                      <date-menu
                          v-model="searchParams.timestamp"
                          @change="getFilterData"
                          :range="true"
                          class-name="q-text-field shadow-0"
                          :flat="true"
                          :dense="true"
                          hide-details="hide-details"
                      >
                      </date-menu>
                    </div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('invoice_no')"
                >
                  <div
                      style="width: 200px"

                  >
                    <div >Order No</div>

                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('date')"
                >
                  <div

                  >
                    <div
                        class=" cursor-pointer"
                        @click="sortColumn('order_date')"
                    >
                      Due Date
                      <v-icon class="ml-2" color="#000" small>
                        mdi-sort{{
                          orderBy == "order_date"
                              ? "-" +
                              (orderDir == "ASC"
                                  ? "ascending"
                                  : "descending")
                              : ""
                        }}
                      </v-icon>
                    </div>
                    <div class="search_column mt-1">
                      <date-menu
                          v-model="searchParams.date"
                          @change="getFilterData"
                          class-name="q-text-field shadow-0"
                          :flat="true"
                          :dense="true"
                          hide-details="hide-details"
                      >
                      </date-menu>
                    </div>
                  </div>
                </th>

                <th
                    v-if="checkTableColumnVisibility('reason')"
                >
                  <div

                  >
                    <div >
                      Refund Reason
                    </div>
                    <div class="search_column"></div>
                  </div>
                </th>

                <th
                    v-if="checkTableColumnVisibility('customer_type')"
                >
                  <div

                  >
                    <div >
                      Customer Type
                    </div>

                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('name')"
                >
                  <div

                  >
                    <div >
                      Customer Name
                    </div>

                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('mobile')"
                >
                  <div

                  >
                    <div >Mobile</div>
                    <div class="search_column">
                      <v-autocomplete
                          solo
                          filled
                          placeholder="Mobile"
                          v-model="searchParams.mobile"
                          item-value="mobile"
                          item-text="mobile"
                          :items="phones"
                          :loading="isLoading"
                          :search-input.sync="phone"
                          @change="getFilterData"
                          class="q-autocomplete shadow-0"
                          flat
                          dense
                          hide-details="hide-details"

                      ></v-autocomplete>
                    </div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('email')"
                >
                  <div

                  >
                    <div >Email</div>
                    <div class="search_column">
                      <v-autocomplete
                          solo
                          filled
                          placeholder="Email"
                          v-model="searchParams.email"
                          item-value="email"
                          item-text="email"
                          :items="emails"
                          :loading="isLoading"
                          :search-input.sync="email"
                          @change="getFilterData"
                          class="q-autocomplete shadow-0"
                          flat
                          dense
                          hide-details="hide-details"
                      ></v-autocomplete>
                    </div>
                  </div>
                </th>
                <th
                    style="position: relative"
                    v-if="checkTableColumnVisibility('age')"
                >
                  <div

                  >
                    <div >Age</div>
                    <div class="search_column">
                      <v-select
                          v-model="searchParams.age"
                          :items="AgeRange"
                          @change="ageChange"
                          placeholder="Age"
                          ref="ageRef"
                          solo
                          class="q-autocomplete shadow-0"
                          flat
                          dense
                          hide-details="hide-details"
                      >
                        <template v-slot:append-item>
                          <v-divider class="mb-2"></v-divider>
                          <div style="width: 150px">
                            <v-list-item>
                              <v-text-field
                                  label="Start Age"
                                  dense
                                  v-model="fromAge"
                                  append-icon="mdi-keyboard_arrow_down"
                              ></v-text-field>
                            </v-list-item>
                            <v-list-item>
                              <v-text-field
                                  label="End Age"
                                  dense
                                  v-model="toAge"
                                  append-icon="mdi-keyboard_arrow_down"
                              ></v-text-field>
                            </v-list-item>
                            <v-list-item>
                              <v-btn
                                  small
                                  color="primary"
                                  @click="ageOkClick"
                              >Ok</v-btn
                              >
                            </v-list-item>
                          </div>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('gender')"
                >
                  <div

                  >
                    <div >Gender</div>
                    <div class="search_column">
                      <v-autocomplete
                          v-model="searchParams.gender"
                          solo
                          filled
                          placeholder="Gender"
                          :items="['All', 'Male', 'Female']"
                          @change="getFilterData"
                          class="q-autocomplete shadow-0"
                          flat
                          dense
                          hide-details="hide-details"
                      ></v-autocomplete>
                    </div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('country')"
                >
                  <div

                  >
                    <div >Country</div>
                    <div class="search_column">
                      <v-autocomplete
                          solo
                          filled
                          placeholder="Country"
                          v-model="searchParams.country_id"
                          item-value="id"
                          item-text="name"
                          :items="['All', ...countries]"
                          @change="getFilterData"

                          class="q-autocomplete shadow-0"
                          flat
                          dense
                          hide-details="hide-details"
                      ></v-autocomplete>
                    </div>
                  </div>
                </th>
                <!-- <th
                  class="md-table-head"
                  style="position: relative"
                  v-if="checkTableColumnVisibility('type')"
                >
                  <div
                    class="
                      md-table-head-container md-ripple md-disabled
                    "
                  >
                    <div class="md-table-head-label">Type</div>
                    <div class="search_column">
                      <v-select
                        v-model="searchParams.product_type_id"
                        label="Type"
                        :items="[
                          { id: null, name: 'All' },
                          ...productTypes,
                        ]"
                        item-text="name"
                        item-value="id"
                        solo
                        filled
                        @change="searchData"
                      ></v-select>
                    </div>
                  </div>
                </th> -->
                <th
                    v-if="checkTableColumnVisibility('service')"
                >
                  <div

                  >
                    <div >Service</div>
                    <div class="search_column">
                      <v-select
                          v-model="searchParams.services"
                          placeholder="Service"
                          :items="venueServices"
                          item-text="name"
                          item-value="venue_service_id"
                          multiple
                          solo
                          filled
                          @change="searchData"
                          class="q-autocomplete shadow-0"
                          flat
                          dense
                          hide-details="hide-details"
                      >
                        <template
                            v-if="
                                      searchParams.services.length ==
                                        venueServices.length
                                    "
                            v-slot:selection="{ index }"
                        >
                                    <span
                                        v-if="index == 0"
                                        class="ellipsis-small"
                                    >All Services</span
                                    >
                        </template>
                        <template
                            v-else
                            v-slot:selection="{ item, index }"
                        >
                                    <span v-if="index == 0">
                                      {{ item.name }}
                                    </span>
                          <span v-if="index == 1"> , ... </span>
                        </template>

                        <template v-slot:prepend-item>
                          <v-list-item
                              ripple
                              @click="
                                        toggle('venueServices', 'services', 1)
                                      "
                          >
                            <v-list-item-action>
                              <v-icon
                                  :color="
                                            searchParams.services.length > 0
                                              ? 'indigo darken-4'
                                              : ''
                                          "
                              >{{
                                  getIcon(
                                      "venueServices",
                                      "services",
                                      1
                                  )
                                }}</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                              >All</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </th>

                <th
                    v-if="checkTableColumnVisibility('title')"
                >
                  <div

                  >
                    <div >
                      {{
                        searchParams.type != null
                            ? searchParams.type
                            : "Activity"
                      }}
                      <br />Name
                    </div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('product_name')"
                >
                  <div

                  >
                    <div >
                      Product Name
                    </div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('price')"
                >
                  <div

                  >
                    <div >Price</div>
                    <div class="search_column">
                      <v-menu
                          v-model="priceMenu"
                          :close-on-content-click="false"
                          :nudge-width="50"
                          max-width="150"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              placeholder="Price"
                              solo
                              v-bind="attrs"
                              v-on="on"
                              class="q-text-field shadow-0"
                              flat
                              dense
                              hide-details="hide-details"
                          ></v-text-field>
                        </template>
                        <v-card width="150">
                          <v-card-text>
                            <v-text-field
                                dense
                                v-model="searchParams.from_amount"
                                placeholder="Price From"
                                class="q-text-field shadow-0"
                                flat

                                hide-details="hide-details"
                            ></v-text-field>
                            <v-text-field
                                class="q-text-field shadow-0"
                                flat
                                dense
                                hide-details="hide-details"
                                v-model="searchParams.to_amount"
                                placeholder="Price To"
                            ></v-text-field>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                @click="
                                          () => {
                                            searchParams.from_amount = null;
                                            searchParams.to_amount = null;
                                            priceMenu = false;
                                            searchData();
                                          }
                                        "
                            >Clear</v-btn
                            >
                            <v-btn
                                color="primary"
                                @click="
                                          () => {
                                            priceMenu = false;
                                            searchData();
                                          }
                                        "
                            >Filter</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </div>
                  </div>
                </th>
                <!--
                <th
                  class="md-table-head"
                  v-if="checkTableColumnVisibility('method')"
                >
                  <div
                    class="
                      md-table-head-container md-ripple md-disabled
                    "
                  >
                    <div class="md-table-head-label">
                      Payment Method
                    </div>


                    <div class="search_column">
                      <v-select
                        v-model="searchParams.payment_method"
                        label="Payment Methods"
                        :items="
                          paymentMethods.concat(
                            paymentMethodFromOnline
                          )
                        "
                        item-text="name"
                        item-value="id"
                        multiple
                        solo
                        filled
                        @change="searchData"
                      >
                        <template
                          v-if="
                            searchParams.payment_method.length ==
                              paymentMethods.length
                          "
                          v-slot:selection="{ index }"
                        >
                          <span v-if="index == 0" class="ellipsis"
                            >All Payment Methods</span
                          >
                        </template>
                        <template
                          v-else
                          v-slot:selection="{ item, index }"
                        >
                          <span v-if="index == 0">
                            {{ item.name }}
                          </span>
                          <span v-if="index == 1"> , ... </span>
                        </template>

                        <template v-slot:prepend-item>
                          <v-list-item
                            ripple
                            @click="
                              toggle(
                                'paymentMethods',
                                'payment_method',
                                1
                              )
                            "
                          >
                            <v-list-item-action>
                              <v-icon
                                :color="
                                  searchParams.payment_method.length >
                                  0
                                    ? 'indigo darken-4'
                                    : ''
                                "
                                >{{
                                  getIcon(
                                    "paymentMethods",
                                    "payment_method",
                                    1
                                  )
                                }}</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                                >All</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </th> -->
                <!-- Transaction status -->
                <th
                    style="position: relative; min-width: 155px"
                    v-if="checkTableColumnVisibility('transaction')"
                >
                  <div

                  >
                    <div >
                      Transaction Status
                    </div>

                    <div class="search_column">
                      <v-select
                          v-model="searchParams.status_id"
                          placeholder="Status"
                          :items="statuses"
                          item-text="name"
                          item-value="id"
                          multiple
                          solo
                          filled
                          @change="searchData"
                          class="q-autocomplete shadow-0"
                          flat
                          dense
                          hide-details="hide-details"
                      >
                        <template
                            v-if="
                                      searchParams.status_id != null &&
                                        searchParams.status_id.length ==
                                          statuses.length
                                    "
                            v-slot:selection="{ index }"
                        >
                                    <span
                                        v-if="index == 0"
                                        class="ellipsis-small"
                                    >All Status</span
                                    >
                        </template>
                        <template
                            v-else
                            v-slot:selection="{ item, index }"
                        >
                                    <span v-if="index == 0">
                                      {{ item.name }}
                                    </span>
                          <span v-if="index == 1"> , ... </span>
                        </template>

                        <template v-slot:prepend-item>
                          <v-list-item
                              ripple
                              @click="
                                        toggle('statuses', 'status_id', 1)
                                      "
                          >
                            <v-list-item-action>
                              <v-icon
                                  :color="
                                            searchParams.status_id != null &&
                                            searchParams.status_id.length > 0
                                              ? 'indigo darken-4'
                                              : ''
                                          "
                              >{{
                                  getIcon("statuses", "status_id", 1)
                                }}</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                              >All</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </th>
                <!-- order status -->
                <th
                    style="position: relative; min-width: 155px"
                    v-if="checkTableColumnVisibility('transaction')"
                >
                  <div

                  >
                    <div >Status</div>

                    <div class="search_column">
                      <v-select
                          v-model="searchParams.order_status_id"
                          placeholder="Status"
                          :items="orderStatuses"
                          item-text="name"
                          item-value="id"
                          multiple
                          solo
                          filled
                          @change="searchData"
                          class="q-autocomplete shadow-0"
                          flat
                          dense
                          hide-details="hide-details"
                      >
                        <template
                            v-if="
                                      searchParams.order_status_id != null &&
                                        searchParams.order_status_id.length ==
                                          statuses.length
                                    "
                            v-slot:selection="{ index }"
                        >
                                    <span
                                        v-if="index == 0"
                                        class="ellipsis-small"
                                    >All Status</span
                                    >
                        </template>
                        <template
                            v-else
                            v-slot:selection="{ item, index }"
                        >
                                    <span v-if="index == 0">
                                      {{ item.name }}
                                    </span>
                          <span v-if="index == 1"> , ... </span>
                        </template>

                        <template v-slot:prepend-item>
                          <v-list-item
                              ripple
                              @click="
                                        toggle(
                                          'orderStatuses',
                                          'order_status_id',
                                          1
                                        )
                                      "
                          >
                            <v-list-item-action>
                              <v-icon
                                  :color="
                                            searchParams.order_status_id !=
                                              null &&
                                            searchParams.order_status_id
                                              .length > 0
                                              ? 'indigo darken-4'
                                              : ''
                                          "
                              >{{
                                  getIcon(
                                      "orderStatuses",
                                      "order_status_id",
                                      1
                                  )
                                }}</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title
                              >All</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('schedule')"
                >
                  <div

                  >
                    <div >Attendance</div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('reciept')"
                >
                  <div

                  >
                    <div >Products</div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('reciept')"
                >
                  <div

                  >
                    <div >
                      Update status
                    </div>
                  </div>
                </th>
                <!-- <th
                  class="md-table-head"
                  v-if="checkTableColumnVisibility('reciept')"
                >
                  <div
                    class="
                      md-table-head-container md-ripple md-disabled
                    "
                  >
                    <div class="md-table-head-label">Bill</div>
                  </div>
                </th> -->
                <th
                    v-if="checkTableColumnVisibility('ticket')"
                >
                  <div

                  >
                    <div >Ticket</div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('schedule')"
                >
                  <div

                  >
                    <div >Details</div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('schedule')"
                >
                  <div

                  >
                    <div >Action</div>
                  </div>
                </th>
                <th
                    v-if="checkTableColumnVisibility('order_notes')"
                >
                  <div

                  >
                    <div >Notes</div>
                  </div>
                </th>
              </tr>
              </thead>

              <tbody>
              <tr
                  class="md-table-row"
                  v-for="(data, index) in logDatas"
                  :key="data.id"
              >
                <td
                    class="md-table-cell"
                    v-if="checkTableColumnVisibility('timestamp')"
                >
                  <div class="md-table-cell-container">
                    {{ data.timestamp | timeStamp }}
                  </div>
                </td>
                <td class="md-table-cell">
                  <div class="md-table-cell-container">
                    {{ data.invoice_seq_no }}
                  </div>
                </td>
                <td
                    class="md-table-cell"
                    v-if="checkTableColumnVisibility('date')"
                >
                  <div class="md-table-cell-container">
                    <div class="user_date">
                      {{ data.order_date | dayFormat }}
                    </div>

                    <!-- <div class="book_time_log">
                      {{ data.start_time.substring(0, 5) }} -
                      {{ data.end_time.substr(0, 5) }}
                    </div>-->
                  </div>
                </td>

                <td
                    class="md-table-cell"
                    v-if="checkTableColumnVisibility('reason')"
                >
                  <div class="md-table-cell-container">
                    {{ data.reason || "--" }}
                  </div>
                </td>

                <td
                    class="md-table-cell"
                    v-if="checkTableColumnVisibility('customer_type')"
                >
                  <div class="md-table-cell-container">
                    {{ data.customer_type || "NA" }}
                  </div>
                </td>
                <td
                    class="md-table-cell"
                    v-if="checkTableColumnVisibility('name')"
                >
                  <div class="md-table-cell-container">
                    <router-link
                        v-if="
                                  checkReadPermission(
                                    $modules.clients.customers.id
                                  ) && data.customer_id
                                "
                        :to="`/clients/customers/` + data.customer_id"
                    >{{ data.first_name }}
                      {{ data.last_name }}</router-link
                    >

                    <span v-else-if="data.customer_id"
                    >{{ data.first_name }}
                                {{ data.last_name || "" }}
                              </span>
                  </div>
                </td>

                <td
                    class="md-table-cell"
                    v-if="checkTableColumnVisibility('mobile')"
                >
                  <div class="md-table-cell-container">
                    {{ data.mobile || "NA" }}
                  </div>
                </td>
                <td
                    class="md-table-cell"
                    v-if="checkTableColumnVisibility('email')"
                >
                  <div class="md-table-cell-container">
                    {{ data.email || "NA" }}
                  </div>
                </td>
                <td
                    class="md-table-cell"
                    v-if="checkTableColumnVisibility('age')"
                >
                  <div class="md-table-cell-container">
                    {{ data.age || "NA" }}
                  </div>
                </td>

                <td
                    class="md-table-cell"
                    v-if="checkTableColumnVisibility('country')"
                >
                  <div class="md-table-cell-container">
                    {{ data.country || "NA" }}
                  </div>
                </td>
                <!-- <td
                  class="md-table-cell"
                  v-if="checkTableColumnVisibility('type')"
                >
                  <div class="md-table-cell-container">
                    {{ data.product_type || "--" }}
                  </div>
                </td> -->
                <td
                    class="md-table-cell"
                    v-if="checkTableColumnVisibility('service')"
                >
                  <div class="md-table-cell-container">
                    {{ data.service_name || "--" }}
                  </div>
                </td>

                <td
                    class="md-table-cell"
                    v-if="checkTableColumnVisibility('title')"
                >
                  <div class="md-table-cell-container">
                    {{ data.title || "NA" }}
                  </div>
                </td>
                <td
                    class="md-table-cell"
                    v-if="checkTableColumnVisibility('product_name')"
                >
                  <div
                      v-if="data.product_name"
                      class="md-table-cell-container product-names-div"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">
                                    {{
                                      getSingleProductName(data.product_name)
                                    }}
                                  </span>
                      </template>
                      <span
                          class="d-block product-name"
                          v-for="(product_name,
                                  index) in data.product_name.split(',')"
                          :key="index"
                      >{{ product_name }}</span
                      >
                    </v-tooltip>
                  </div>
                </td>
                <td
                    class="md-table-cell"
                    v-if="checkTableColumnVisibility('price')"
                >
                  <div
                      class="md-table-cell-container text-capitalize"
                  >
                    {{ Number(Math.abs(data.total)) | toCurrency }}
                  </div>
                </td>
                <!-- <td
                  class="md-table-cell"
                  v-if="checkTableColumnVisibility('method')"
                >
                  <div
                    class="md-table-cell-container text-capitalize"
                  >
                    {{
                      data.status != "Refund"
                        ? data.payment_method_name
                        : data.refund_payment_method_name || "NA"
                    }}
                    {{
                      data.card_number
                        ? "- (" +
                          data.card_type +
                          " " +
                          data.card_number.slice(-4) +
                          ")"
                        : ""
                    }}
                  </div>
                </td> -->
                <td
                    class="md-table-cell"
                    v-if="checkTableColumnVisibility('transaction')"
                >
                  <div
                      class="md-table-cell-container text-capitalize"
                  >
                    {{ data.status || "NA" }}
                    <!-- <span
                      v-if="
                        data.status == 'Refund' &&
                        data.refund_payment_method_name
                      "
                      >({{ data.refund_payment_method_name }})</span
                    > -->
                  </div>
                </td>
                <td
                    class="md-table-cell"
                    v-if="checkTableColumnVisibility('transaction')"
                >
                  <div
                      class="md-table-cell-container text-capitalize"
                  >
                    {{ data.invoice_status || "NA" }}
                  </div>
                </td>
                <td
                    class="md-table-cell"
                    v-if="checkTableColumnVisibility('schedule')"
                >
                  <div class="md-table-cell-container">
                    <v-btn
                        v-if="
                                  data.facility_booking_id != null &&
                                    data.is_capacity == 1
                                "
                        normal
                        small
                        @click="openParticipants(data)"
                    >
                      <span>View</span>
                    </v-btn>
                    <span v-else>NA</span>
                  </div>
                </td>
                <td class="md-table-cell">
                  <div class="md-table-cell-container">
                    <v-btn
                        normal
                        small
                        @click="getOrderProducts(data.id)"
                    >
                      <span>View Details</span>
                    </v-btn>
                  </div>
                </td>
                <td class="md-table-cell">
                  <div class="md-table-cell-container">
                    <v-btn
                        normal
                        small
                        class="white--text"
                        @click="toggleOrder(data.id,data.invoice_status)"
                        :color="getColor(data.invoice_status)"
                    >
                                <span>{{
                                    data.invoice_status == "Pending"
                                        ? "Activate"
                                        : data.invoice_status == "Active"
                                            ? "Complete"
                                            : "Completed"
                                  }}</span>
                    </v-btn>
                  </div>
                </td>
                <!-- <td class="md-table-cell">
                  <div class="md-table-cell-container">
                    <v-btn
                      normal
                      small
                      @click="getOrderDetails(data)"
                    >
                      <span
                        v-if="
                          data.status == 'Unpaid' ||
                            (data.paid_at == null &&
                              !['Rescheduled'].includes(
                                data.order_status_name
                              ))
                        "
                        >Invoice</span
                      >
                      <span v-else-if="data.status == 'Refund'"
                        >Refund Bill</span
                      >
                      <span v-else>Receipt</span>
                    </v-btn>
                  </div>
                </td> -->
                <td
                    class="md-table-cell"
                    v-if="checkTableColumnVisibility('ticket')"
                >
                  <div class="md-table-cell-container">
                    <v-btn normal small @click="viewTicket(data)"
                    >Ticket</v-btn
                    >
                  </div>
                </td>
                <td
                    class="md-table-cell"
                    v-if="checkTableColumnVisibility('schedule')"
                >
                  <div class="md-table-cell-container">
                    <v-btn
                        normal
                        small
                        @click="gotoDetails(index)"
                        v-if="
                                  data.status != 'Refund' &&
                                    data.status != 'Cancelled' &&
                                    data.status != 'Void' &&
                                    data.order_status_name !=
                                      'Reservation Cancel' &&
                                    data.order_status_name != 'Cancelled' &&
                                    data.product_type != 'Credit Settlement' &&
                                    data.order_status_name != 'Refund' &&
                                    data.order_status_name != 'Cancelled' &&
                                    data.order_status_name !=
                                      'Partial Refund' &&
                                    data.order_status_name != 'Partial Cancel'
                                "
                    >Details</v-btn
                    >
                    <span v-else>NA</span>
                  </div>
                </td>
                <td
                    class="md-table-cell"
                    v-if="checkTableColumnVisibility('schedule')"
                >
                  <div class="md-table-cell-container">
                    <v-btn
                        normal
                        small
                        v-if="data.status != 'Refund'"
                        @click="creditSettle(data.order_id)"
                    >Settle</v-btn
                    >
                    <span v-if="data.status == 'Refund'">NA</span>
                  </div>
                </td>
                <td
                    class="md-table-cell"
                    v-if="checkTableColumnVisibility('order_notes')"
                >
                  <div class="md-table-cell-container">
                    {{ data.order_notes || "--" }}
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
    <v-row>
      <v-col cols="4"></v-col>
      <v-col cols="4">
        <v-pagination v-model="page" :length="totalPages" class="new-pagination" total-visible="7"></v-pagination>
      </v-col>
      <v-col class="d-flex align-center justify-end" cols="4">
        <div class="d-flex align-center justify-end text-sm gap-x-2">
                <span class="">
                  Result
                </span>
          <div style="width: 80px">
            <v-select
                v-model="perPage"
                :items="[10, 15, 25, 50]"
                :menu-props="{ bottom: true, offsetY: true }"
                class="q-autocomplete text-sm"
                flat
                hide-details
                solo
                @change="getFilterData"
            ></v-select>
          </div>
          <span>Per Page</span>
        </div>
      </v-col>
    </v-row>


    <order-details
      :id="orderId"
      :ids="orderIds"
      :log="true"
      @openMainOrderDetails="openMainOrderDetails"
      @close="((orderId = null), (orderIds = null)), searchData()"
    ></order-details>

    <v-dialog v-model="productsModal" scrollable width="60%">
      <v-card>

        <v-card-text style="margin-top:20px">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold" text="  Products in order" style="color: black" >
                </SvgIcon>
                <v-btn  fab x-small class="shadow-0" @click="closeModal">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
         <div class="row">
           <div class="col-md-12">
             <table class="table border-collapse text-center">
               <thead>
               <tr class="opacity-70 tr-neon tr-rounded text-center">
                 <th>
                   Product Name
                 </th>
                 <th>
                   Quantity
                 </th>
                 <th>Customer name</th>
                 <th>Deliver to</th>
               </tr>
               </thead>
               <tbody>
               <tr v-for="(product, index) in productsList" :key="index">
                 <td>{{ product.product_name }}</td>

                 <td>
                   {{ product.quantity }}
                 </td>

                 <td>
                   {{ product.customer_name }}
                 </td>

                 <td>
                   {{ product.invoice_notes ? product.invoice_notes : product.delivery_address ? product.delivery_address : '-' }}
                 </td>
               </tr>
               </tbody>
             </table>
           </div>
         </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2 white--text blue-color" @click="closeModal"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import moment from "moment";
import OrderDetails from "@/components/Order/OrderDetails.vue";
import { mapGetters } from "vuex";
import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {
  props: {
    outletId: {
      type: Number,
      default: null,
    },
    tab: {
      type: String,
      default: 'sales',
    },
  },
  components: {SvgIcon, OrderDetails },
  data() {
    return {
      timer:null,
      menu1: false,
      menu2: false,
      invoiceAmount: 0,
      priceMenu: false,
      logDatas: [],
      orderBy: "id",
      orderDir: "DESC",
      status_id: null,
      product_names: [],
      product_name: null,
      names: [],
      isLoading: false,
      date1: moment()
        .subtract(30, "days")
        .format("YYYY-MM-DD"),
      date2: moment().format("YYYY-MM-DD"),
      searchParams: {
        services: [],
        payment_method: [],
        timestamp: [],
        status_id: null,
        start_date: moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      },
      timeDuration: [
        { name: "All", title: "All" },
        { name: "week", title: "This Week" },
        { name: "year", title: "This Year" },
        { name: "month", title: "This Month" },
        { name: "custom", title: "Custom Duration" },
      ],
      flag: false,
      perPage: 10,
      totalPages: 1,
      columnData: [],
      columnData_list: [
        { name: "Timestamp", value: "timestamp" },
        { name: "Transaction No", value: "bill_no" },
        { name: "Original Transaction No", value: "original_trans_no" },
        { name: "Cashier name", value: "cashier_name" },
        { name: "Due Date", value: "date" },
        { name: "Customer Type", value: "customer_type" },
        { name: "Customer Name", value: "name" },
        { name: "Customer Mobile", value: "mobile" },
        { name: "Customer Email", value: "email" },
        { name: "Booking Type", value: "type" },
        { name: "Service", value: "service" },
        { name: "Product Name", value: "product_name" },

        // { name: "Event/Class Name", value: "title" },
        // { name: 'Location', value: 'location' },
        { name: "Price", value: "price" },
        // { name: "Payment Method", value: "method" },
        { name: "Transation Status", value: "transaction" },
        { name: "Receipt", value: "reciept" },
        { name: "Ticket", value: "ticket" },
        { name: "Schedule Details", value: "schedule" },
        { name: "Notes", value: "order_notes" },
      ],
      columns: [],
      columns_list: [
        "timestamp",
        "bill_no",
        "invoice_no",
        "original_trans_no",
        "cashier_name",
        // "date",
        "name",
        "type",
        // "service",
        "product_name",
        "price",
        // "method",
        "reciept",
        // "ticket",
        // "schedule",
        "status",
        "transaction",
      ],
      page: 1,
      paymentMethodFromOnline: [
        {
          id: 15,
          name: "Online",
          status_id: 1,
          type: "online",
        },
        {
          id: 16,
          name: "Krews",
          status_id: 1,
          type: "online",
        },
        {
          id: 18,
          name: "Kiosk",
          status_id: 1,
          type: "online",
        },
      ],
      productsList: [],
      productsModal: false,
      orderId: null,
      orderIds: null,
      currentOrder: null,
      paymentModel: { invoiceId: null, type: "details", orderIds: [] },
      payInvoiceData: {},
    };
  },
  mounted() {
    this.status_id = [4, 5, 8, 17, 21, 22];

    this.columns = [];
    this.columns = this.columns_list.filter((e) => e !== "original_trans_no");
    // this.columns.push("schedule");
    this.columnData = [];
    this.columnData = this.columnData_list.filter(
      (e) => e.value !== "original_trans_no"
    );

    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
    if (this.$store.getters.getPaymentMethods.status == false) {
      this.$store.dispatch("loadPaymentMethods", "normal");
    }
    if (this.$store.getters.getStatuses.status == false) {
      this.$store.dispatch("loadStatuses").then(() => {});
    } else {
      this.searchParams.status_id = [];
      this.statuses.forEach((element) => {
        if (element.id) {
          this.searchParams.status_id.push(element.id);
        }
      });
      // this.searchData();
    }

    if (this.$store.getters.getOrderStatuses.status == false) {
      this.$store.dispatch("loadOrderStatuses").then(() => {
        this.searchParams.order_status_id = [];
        this.searchData();
      });
    } else {
      this.searchParams.order_status_id = [];
      // console.log("search data 8");
      this.searchData();
    }

    setTimeout(() => {
      this.btnShow = true;
    }, 10);

    if (this.$store.getters.getCardTypes.status == false) {
      this.$store.dispatch("loadCardTypes").then((response) => {
        response.forEach((el) => {
          this.paymentMethodFromOnline.push({
            id: el.id.toString(),
            name: "Card" + " ( " + el.name + " )",
            status_id: 1,
            type: "online",
          });
        });
      });
    } else {
      this.$store.getters.getCardTypes.data.forEach((el) => {
        this.paymentMethodFromOnline.push({
          id: el.id.toString(),
          name: "Card" + " ( " + el.name + " )",
          status_id: 1,
          type: "online",
        });
      });
    }

    this.timer = setInterval(()=>{
      this.searchData();
    },30000)
  },
  beforeDestroy(){
    clearInterval(this.timer);
  },
  computed: {
    ...mapGetters({
      getVenueServices: "getVenueServices",
      getSportsCategory: "getSportsCategory",
    }),
    date1Formatted() {
      return moment(this.searchParams.start_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.end_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    countries() {
      return this.$store.getters.getCountries.data;
    },
    venueCode() {
      return this.$store.getters.venueInfo.code.toUpperCase();
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
    paymentMethods() {
      return this.$store.getters.getPaymentMethods.data;
    },
    statuses() {
      return this.$store.getters.getStatuses.data;
    },
    orderStatuses() {
      return this.$store.getters.getOrderStatuses.data;
    },
  },
  watch: {
    tab(val){
      if(val !== 'sales'){
        clearInterval(this.timer);
      }else{
        this.timer = setInterval(()=>{
          this.searchData();
        },30000)
      }
    },
    page() {
      this.searchData();
    },
  },
  methods: {
    getColor(status) {
      if (status == "Pending") {
        return "red";
      } else if (status == "Active") {
        return "orange";
      } else {
        return "green";
      }
    },
    toggle(type, field, searchP) {
      this.$nextTick(() => {
        if (searchP) {
          if (
            this.searchParams[field] != null &&
            this.searchParams[field].length == this[type].length
          ) {
            this.searchParams[field] = [];
          } else {
            this.searchParams[field] = this[type].map((x) => x.id);
          }
        } else {
          if (this[field].length == this[type].length) {
            this[field] = [];
          } else {
            this.searchParams[field] = this[type];
          }
        }
        this.searchData();
      });
      this.$forceUpdate();
    },
    toggleOrder(id,status) {
        if(status == 'Completed'){
            this.showError('Order Already Completed');
            return;
        }
      this.showLoader("Loading Products");
      let url = `venues/outlets/orders/update-status?invoice_id=${id}`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.status == 200) {
            this.searchData();
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    closeModal() {
      this.productsList = [];
      this.productsModal = false;
    },
    getOrderProducts(id) {
      this.showLoader("Loading Products");
      let url = `venues/outlets/orders/get-products?outlet_id=${this.outletId}&id=${id}`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            if (data && data.length) {
              this.productsList = data;
              this.productsModal = true;
            } else {
              this.productsList = [];
            }
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    openMainOrderDetails(id) {
      this.orderId = id;
    },
    getIcon(type, field, searchP) {
      let icon = "mdi-checkbox-blank-outline";
      if (searchP) {
        if (
          this.searchParams[field] != null &&
          this.searchParams[field].length == this[type].length
        ) {
          icon = "mdi-close-box";
        }
        if (
          this.searchParams[field] != null &&
          this.searchParams[field].length > 0 &&
          this.searchParams[field].length != this[type].length
        )
          icon = "mdi-minus-box";
      } else {
        if (this[field].length == this[type].length) {
          icon = "mdi-close-box";
        }
        if (this[field].length > 0 && this[field].length != this[type].length)
          icon = "mdi-minus-box";
      }
      return icon;
    },
    getFilterData() {
      this.page = 1;
      this.searchData();
    },
    // salesReportExcelDownload() {

    //   this.showLoader("Downloading report");
    //   let url = "";
    //   if (
    //     typeof this.$route.name != "undefined" &&
    //     this.$route.name == "Refunds"
    //   ) {
    //     url += "&status=2";
    //   }

    //   if (
    //     this.searchParams.time_intervel != "All" &&
    //     this.searchParams.time_intervel != null
    //   ) {
    //     // this.searchParams.end_date = moment().format("YYYY-MM-DD");
    //     // if (this.searchParams.time_intervel == "custom") {
    //     //   this.searchParams.start_date = moment()
    //     //     .subtract(30, "days")
    //     //     .format("YYYY-MM-DD");
    //     //   this.flag = true;
    //     // }
    //     // else
    //     if (this.searchParams.time_intervel == "week") {
    //       this.searchParams.end_date = moment().format("YYYY-MM-DD");
    //       this.searchParams.start_date = moment()
    //         .startOf("week")
    //         .format("YYYY-MM-DD");
    //     } else if (this.searchParams.time_intervel == "month") {
    //       this.searchParams.end_date = moment().format("YYYY-MM-DD");
    //       this.searchParams.start_date = moment()
    //         .startOf("month")
    //         .format("YYYY-MM-DD");
    //     } else if (this.searchParams.time_intervel == "year") {
    //       this.searchParams.end_date = moment().format("YYYY-MM-DD");
    //       this.searchParams.start_date = moment()
    //         .startOf("year")
    //         .format("YYYY-MM-DD");
    //     }
    //     url += "&end_date=" + this.searchParams.end_date;
    //     url += "&start_date=" + this.searchParams.start_date;
    //   }
    //   if (this.searchParams.product_type_id != null) {
    //     url += `&product_type_id=${this.searchParams.product_type_id}`;
    //   }
    //   if (
    //     typeof this.searchParams.reason != "undefined" &&
    //     this.searchParams.reason.length > 0
    //   ) {
    //     url += `&reason=${this.searchParams.reason}`;
    //   }
    //   if (
    //     this.searchParams.services != "All" &&
    //     this.searchParams.services != null &&
    //     this.searchParams.services.length > 0
    //   ) {
    //     url += `&venue_service_id=${this.searchParams.services}`;
    //   }
    //   if (this.searchParams.date == null) {
    //     this.searchParams.date = null;
    //   } else if (typeof this.searchParams.date != "undefined") {
    //     url += "&date=" + this.searchParams.date;
    //   }
    //   if (this.searchParams.timestamp == null) {
    //     delete this.searchParams.timestamp;
    //   } else if (typeof this.searchParams.timestamp != "undefined") {
    //     url += "&timestamp=" + this.searchParams.timestamp;
    //   }
    //   if (this.searchParams.name == "All") {
    //     this.searchParams.name = null;
    //   } else if (typeof this.searchParams.name != "undefined") {
    //     url += "&name=" + this.searchParams.name;
    //   }
    //   if (this.searchParams.email == "All") {
    //     delete this.searchParams.email;
    //   } else if (typeof this.searchParams.email != "undefined") {
    //     url += "&email=" + this.searchParams.email;
    //   }
    //   if (this.searchParams.mobile == "All") {
    //     delete this.searchParams.mobile;
    //   } else if (typeof this.searchParams.mobile != "undefined") {
    //     url += "&mobile=" + this.searchParams.mobile;
    //   }
    //   if (this.searchParams.gender == "All") {
    //     delete this.searchParams.gender;
    //   } else if (typeof this.searchParams.gender != "undefined") {
    //     url += "&gender=" + this.searchParams.gender;
    //   }
    //   if (this.searchParams.customer_type == null) {
    //     delete this.searchParams.customer_type;
    //   } else if (typeof this.searchParams.customer_type != "undefined") {
    //     url += "&customer_type=" + this.searchParams.customer_type;
    //   }
    //   if (this.searchParams.country_id == "All") {
    //     delete this.searchParams.country_id;
    //   } else if (typeof this.searchParams.country_id != "undefined") {
    //     url += "&country_id=" + this.searchParams.country_id;
    //   }

    //   if (this.searchParams.cashier_name == "All") {
    //     delete this.searchParams.cashier_name;
    //   } else if (this.searchParams.cashier_name) {
    //     url += "&cashier_name=" + this.searchParams.cashier_name;
    //   }

    //   if (
    //     typeof this.searchParams.from_age != "undefined" &&
    //     typeof this.searchParams.to_age != "undefined"
    //   ) {
    //     url +=
    //       "&from_age=" +
    //       this.searchParams.from_age +
    //       "&to_age=" +
    //       this.searchParams.to_age;
    //   }
    //   if (this.searchParams.from_amount != null) {
    //     url += "&from_amount=" + this.searchParams.from_amount;
    //   }
    //   if (this.searchParams.to_amount != null) {
    //     url += "&to_amount=" + this.searchParams.to_amount;
    //   }

    //   if (this.searchParams.bill_no) {
    //     url += "&bill_no=" + this.searchParams.bill_no;
    //   }
    //   if (this.searchParams.invoice_no) {
    //     url += "&invoice_no=" + this.searchParams.invoice_no;
    //   }

    //   if (this.eventId > 0) {
    //     url += "&event_id=" + this.eventId;
    //   }

    //   if (this.promotionId > 0) {
    //     url += "&promotion_id=" + this.promotionId;
    //   }
    //   // if (typeof this.searchParams.payment_method_id != "undefined") {
    //   //   url += "&payment_method=" + this.searchParams.payment_method_id;
    //   // }

    //   if (
    //     this.searchParams.payment_method != "All" &&
    //     this.searchParams.payment_method != null &&
    //     this.searchParams.payment_method.length > 0
    //   ) {
    //     let cardTypeId = [];
    //     let paymentMethod = [];
    //     this.searchParams.payment_method.forEach((ex) => {
    //       if (typeof ex == "string") {
    //         cardTypeId.push(Number(ex));
    //       } else {
    //         paymentMethod.push(ex);
    //         if (ex == 6) {
    //           paymentMethod.push(11);
    //         }
    //       }
    //     });

    //     if (this.searchParams.payment_method[0] == 11) {
    //       this.searchParams.payment_method = [];
    //       paymentMethod = [];
    //     }
    //     if (paymentMethod.length) {
    //       url += `&payment_method=${paymentMethod}`;
    //     }

    //     if (cardTypeId.length) {
    //       url += `&card_type_id=${cardTypeId}`;
    //     }
    //   }

    //   if (
    //     this.searchParams.order_status_id != "All" &&
    //     this.searchParams.order_status_id != null &&
    //     this.searchParams.order_status_id.length > 0
    //   ) {
    //     url += `&order_status_id=${this.searchParams.order_status_id}`;
    //   } else {
    //     this.searchParams.order_status_id = null;
    //   }
    //   if (this.refundClass) {
    //     url += "&status_id=8&refund=true";
    //   } else if (this.cancellationClass) {
    //     url += "&status_id=13";
    //   } else if (this.searchParams.status_id != null && !this.creditClass) {
    //     url += "&status_id=" + this.searchParams.status_id;
    //   } else if (this.status_id != null && !this.creditClass) {
    //     url += "&status_id=" + this.status_id;
    //   } else {
    //     url += "&credit";
    //   }
    //   url += "&visible_fields=" + this.columns.join(",");

    //   this.searchParams.page = this.page;
    //   this.searchParams.per_page = this.perPage != null ? this.perPage : 10;
    //   this.searchParams.order_by = this.orderBy;
    //   this.searchParams.order_by_value = this.orderDir;
    //   this.$http
    //     .get(
    //       "venues/sales/report/download?page=" +
    //         this.page +
    //         "&per_page=" +
    //         (this.perPage != null ? this.perPage : 10) +
    //         "&order_by=" +
    //         this.orderBy +
    //         "&sort_order=" +
    //         this.orderDir +
    //         url,
    //       {
    //         responseType: "blob",
    //       }
    //     )
    //     .then((response) => {
    //       this.hideLoader();
    //       if (response.status == 200) {
    //         this.downloadFile(response, "ordersReport");
    //       }
    //     })
    //     .catch((error) => {
    //       this.errorChecker(error);
    //     });

    // },
    changeFxn() {
      if (this.searchParams.time_intervel == "custom") {
        this.searchParams.start_date = moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD");
        this.searchParams.end_date = moment().format("YYYY-MM-DD");
        this.flag = true;
      } else {
        this.flag = false;
        this.searchData();
      }
    },
    checkTableColumnVisibility(column) {
      let index = this.columns.findIndex((item) => item == column);
      if (index == -1) {
        return false;
      }
      return true;
    },
    getSingleProductName(data) {
      if (data) {
        let product_name = data.split(",");
        if (product_name.length > 1) {
          return product_name[0] + " ...";
        } else {
          return product_name[0];
        }
      } else {
        return "";
      }
    },
    getOrderDetails(data) {
      if (data.parent_order_ids) {
        this.orderIds = [data.order_id];
        data.parent_order_ids.split(",").forEach((id) => {
          this.orderIds.push(Number(id));
        });
      } else {
        this.orderId = data.order_ids[0];
      }
    },
    searchData() {
      this.invoiceAmount = 0;
      this.selectedOrders = [];
      this.showLoader("Loading..");
      let url = "";
      if (
        typeof this.$route.name != "undefined" &&
        this.$route.name == "Refunds"
      ) {
        url += "&status=2";
      }

      if (
        this.searchParams.time_intervel != "All" &&
        this.searchParams.time_intervel != null
      ) {
        // this.searchParams.end_date = moment().format("YYYY-MM-DD");
        if (this.searchParams.time_intervel == "custom") {
          this.flag = true;
        } else if (this.searchParams.time_intervel == "week") {
          this.searchParams.end_date = moment().format("YYYY-MM-DD");
          this.searchParams.start_date = moment()
            .startOf("week")
            .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "month") {
          this.searchParams.end_date = moment().format("YYYY-MM-DD");
          this.searchParams.start_date = moment()
            .startOf("month")
            .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "year") {
          this.searchParams.end_date = moment().format("YYYY-MM-DD");
          this.searchParams.start_date = moment()
            .startOf("year")
            .format("YYYY-MM-DD");
        }
        url += "&end_date=" + this.searchParams.end_date;
        url += "&start_date=" + this.searchParams.start_date;
      }
      if (this.searchParams.product_type_id != null) {
        url += `&product_type_id=${this.searchParams.product_type_id}`;
      }
      if (
        this.searchParams.product_id != null &&
        this.searchParams.product_id != "All"
      ) {
        url += `&product_id=${this.searchParams.product_id}`;
      }
      if (
        typeof this.searchParams.reason != "undefined" &&
        this.searchParams.reason.length > 0
      ) {
        url += `&reason=${this.searchParams.reason}`;
      }
      if (
        this.searchParams.services != "All" &&
        this.searchParams.services != null &&
        this.searchParams.services.length > 0
      ) {
        url += `&venue_service_id=${this.searchParams.services}`;
      }
      if (this.searchParams.date == null) {
        this.searchParams.date = null;
      } else if (typeof this.searchParams.date != "undefined") {
        url += "&date=" + this.searchParams.date;
      }
      if (this.searchParams.timestamp == null) {
        delete this.searchParams.timestamp;
      } else if (typeof this.searchParams.timestamp != "undefined") {
        if (this.searchParams.timestamp.length > 1) {
          this.searchParams.timestamp.sort((a, b) => new Date(a) - new Date(b));
        }
        url += "&timestamp=" + this.searchParams.timestamp;
      }
      if (this.searchParams.name == "All") {
        delete this.searchParams.name;
      } else if (this.searchParams.name) {
        url += "&name=" + this.searchParams.name;
      }

      if (this.searchParams.cashier_name == "All") {
        delete this.searchParams.cashier_name;
      } else if (this.searchParams.cashier_name) {
        url += "&cashier_name=" + this.searchParams.cashier_name;
      }

      if (this.searchParams.email == "All") {
        delete this.searchParams.email;
      } else if (this.searchParams.email) {
        url += "&email=" + this.searchParams.email;
      }
      if (this.searchParams.mobile == "All") {
        delete this.searchParams.mobile;
      } else if (this.searchParams.mobile) {
        url += "&mobile=" + this.searchParams.mobile;
      }
      if (this.searchParams.gender == "All") {
        this.searchParams.gender = null;
      } else if (this.searchParams.gender) {
        url += "&gender=" + this.searchParams.gender;
      }
      if (this.searchParams.customer_type == null) {
        delete this.searchParams.customer_type;
      } else if (this.searchParams.customer_type) {
        url += "&customer_type=" + this.searchParams.customer_type;
      }
      if (this.searchParams.country_id == "All") {
        delete this.searchParams.country_id;
      } else if (this.searchParams.country_id) {
        url += "&country_id=" + this.searchParams.country_id;
      }
      if (
        typeof this.searchParams.from_age != "undefined" &&
        typeof this.searchParams.to_age != "undefined"
      ) {
        url +=
          "&from_age=" +
          this.searchParams.from_age +
          "&to_age=" +
          this.searchParams.to_age;
      }
      if (this.searchParams.from_amount) {
        url += "&from_amount=" + this.searchParams.from_amount;
      }
      if (this.searchParams.to_amount) {
        url += "&to_amount=" + this.searchParams.to_amount;
      }
      if (this.searchParams.bill_no) {
        url += "&bill_no=" + this.searchParams.bill_no;
      }
      if (this.searchParams.invoice_no) {
        url += "&invoice_no=" + this.searchParams.invoice_no;
      }

      if (
        this.searchParams.order_status_id != "All" &&
        this.searchParams.order_status_id != null &&
        this.searchParams.order_status_id.length > 0
      ) {
        url += `&order_status_id=${this.searchParams.order_status_id}`;
      } else {
        this.searchParams.order_status_id = null;
      }

      //   if (this.eventId > 0) {
      //     url += "&event_id=" + this.eventId;
      //   }

      if (this.promotionId > 0) {
        url += "&promotion_id=" + this.promotionId;
      } else if (
        this.searchParams.status_id != null &&
        this.searchParams.status_id.length > 0
      ) {
        url += "&status_id=" + this.searchParams.status_id;
      } else if (this.status_id != null) {
        url += "&status_id=" + this.status_id;
      }

      this.searchParams.page = this.page;
      this.searchParams.per_page = this.perPage != null ? this.perPage : 10;
      this.searchParams.order_by = this.orderBy;
      this.searchParams.order_by_value = this.orderDir;
      this.$http
        .get(
          "venues/outlets/orders?page=" +
            this.page +
            "&per_page=" +
            (this.perPage != null ? this.perPage : 10) +
            "&order_by=" +
            this.orderBy +
            "&sort_order=" +
            this.orderDir +
            "&outlet_id=" +
            this.outletId +
            url
        )
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            this.logDatas = response.data.data;
            this.totalPages = response.data.total_pages;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>
