<template>
  <v-container fluid>
    <v-row class=" mb-5">
      <v-col xl="3" lg="2" md="2" sm="12">
        <BackButton :handler="gotoOutlet"/>
      </v-col>
      <v-col lg="4" md="4" sm="12">
        <div class="d-flex justify-center gap-x-6 align-center">
          <div style="max-width: 16rem;max-height: 10rem">
            <view-image :image="outlet.image_path ? outlet.image_path : ''"  :width="200" :height="165" class="rounded-lg" defaultImage="user"/>
          </div>
          <div class="mt-2">
            <h3 class="text-xl m-b-4">
              {{ outlet.name }}
            </h3>
            <div class="d-block ">
              <SvgIcon class="gap-x-4 m-b-4 font-medium">
                <template v-slot:icon>
                  <ContactPersonIcon/>
                </template>
                {{ outlet.first_name }} {{ outlet.last_name || "" }}
              </SvgIcon>
            </div>
            <div class="d-block ">
              <SvgIcon class="gap-x-4 m-b-4 font-medium">
                <template v-slot:icon>
                  <SMSIcon/>
                </template>
                {{ outlet.email }}
              </SvgIcon>
            </div>
            <div class="d-block">
              <SvgIcon class="gap-x-4 m-b-4 font-medium">
                <template v-slot:icon>
                  <CallIcon/>
                </template>
                {{ outlet.mobile }}
              </SvgIcon>
            </div>
          </div>
        </div>

      </v-col>
      <v-col lg="3" sm="12" class="mt-sm-3  d-sm-flex">
        <v-btn
            v-if="checkDeletePermission($modules.outlet.management.slug)"
            class="ma-2 text_capitalize "
            @click="
            confirmDelete({
              id: outlet.id,
              status_id: outlet.status_id,
              flag: true,
            })
          "
            outlined
            :color="outlet.status_id == 1?'red':''"

        >
          <span class="ml-1">{{ outlet.status_id == 1 ? "Deactivate" : "Activate" }}</span>
        </v-btn>
        <v-btn
            v-if="checkWritePermission($modules.outlet.management.slug)"
            style="background-color: rgba(17, 42, 70, 0.1) ; color:#112A46 "
            class="ma-2  text_capitalize "
            outlined
            @click="editOutlet(outlet.id)"

        > <EditBtnIcon/>
          <span class="ml-1">Edit</span>   </v-btn>
      </v-col>
    </v-row>


    <!-- <v-card flat class="d-flex">
      <view-image
        :image="outlet.image_path"
        :height="330"
        defaultImage="outlet"
      >
        <template slot="overlay">
          <v-row
            class="fill-height ma-0 hoverImage"
            align="center"
            justify="center"
          >
            <v-btn small @click="gotoOutlet" absolute right style="top: 30px">
              <v-icon small>mdi-backburger</v-icon>
              Back
            </v-btn>
            <div
              align="center"
              justify="center"
              class="white--text eventbox pa-4"
            >
              <div class="pa-2 display-1">{{ outlet.name }}</div>
              <div class="pa-2 title"></div>
            </div>
          </v-row>
        </template>
      </view-image>
    </v-card> -->


    <div class="row mt-lg-15 mt-md-15 mt-sm-5">
      <div class="col-12">
        <v-card class="shadow-0">
          <v-card-actions class="text-center" >
            <v-tabs
                v-model="tab"
                background-color="transparent"
                centered
                light
                class="q-tabs-secondary shadow-0 border-bottom"
                slider-color="transparent"
            >
              <v-tab href="#details"
                     active-class="svg-stroke-neon"
                     v-if="checkReadPermission($modules.outlet.productsOrder.slug)"

              >
                <SvgIcon text="Details">
                  <template v-slot:icon>
                    <PackagesIcon />
                  </template>
                </SvgIcon>

              </v-tab>
              <v-tab href="#products"
                     v-if="checkReadPermission($modules.outlet.productsOrder.slug)"

                     active-class="svg-stroke-neon"
                     class="d-flex  ">
                <SvgIcon text="Products">
                  <template v-slot:icon>
                    <SalesIcon />
                  </template>
                </SvgIcon>
              </v-tab>
              <v-tab href="#sales"
                     v-if="checkReadPermission($modules.outlet.productsOrder.slug)"

                     active-class="svg-stroke-neon"
                     class="d-flex  ">
                <SvgIcon text="Orders">
                  <template v-slot:icon>
                    <SaleIcon/>
                  </template>
                </SvgIcon>
              </v-tab>
              <v-tab href="#logs"
                     v-if="checkReadPermission($modules.outlet.productsOrder.slug)"

                     active-class="svg-stroke-neon"
                     class="d-flex  ">
                <SvgIcon text="Logs">
                  <template v-slot:icon>
                    <LogsIcon/>
                  </template>
                </SvgIcon>
              </v-tab>

              <v-tab href="#analytics"
                     v-if="checkReadPermission($modules.outlet.productsOrder.slug)"

                     active-class="svg-stroke-neon"
                     class="d-flex  ">
                <SvgIcon text="Charts">
                  <template v-slot:icon>
                    <AnalyticsIcon/>
                  </template>
                </SvgIcon>
              </v-tab>
            </v-tabs>

          </v-card-actions>
          <v-card-text>
            <v-row no-gutters >
              <v-tabs-items v-model="tab" touchless>
                <v-tab-item value="details">
                  <outlet-details
                      :outletId="id"
                      :outletName="outlet.name"
                      :description="outlet.description"
                      @reload="getOutletDetails"
                  />
                </v-tab-item>

                <v-tab-item value="products" touchless>
                  <!-- <outlet-products :outletId="id"></outlet-products> -->
                  <OutletPos :outletId="id" />
                </v-tab-item>

                <v-tab-item value="sales" touchless>
                  <outlet-orders :tab="tab" :outletId="id"></outlet-orders>
                </v-tab-item>

                <v-tab-item value="logs" touchless>
                  <outlet-logs :outletId="id"></outlet-logs>
                </v-tab-item>

                <v-tab-item value="analytics" touchless>
                  <outlet-analytics :outletId="id"></outlet-analytics>
                </v-tab-item>

                <v-tab-item value="customer" touchless>
                  <customer-analysis
                      :params="params"
                      :showProductType="false"
                      :exportPermission="
              checkExportPermission($modules.outlet.management.slug)
            "
                      stackType="all"
                  ></customer-analysis>
                </v-tab-item>
              </v-tabs-items>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </div>

  <outlet-add-edit-modal :outlet_-i-d="outlet.id" :add-outlet-dialog="addOutletDialog"
                          v-if="addOutletDialog"
                         :roles="roles" :venue-services="venueServices" :venue-service-ids="venueServiceIds" @cancelOutlet="cancelOutlet"
  />

    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>


  </v-container>
</template>

<script>
import CustomerAnalysis from "@/components/Chart/CustomerPieCharts.vue";
// import OutletSales from "@/components/Chart/OrderSalesGraph";
import OutletLogs from "./OutletTabs/OutletLogs";
import OutletAnalytics from "./OutletTabs/OutletAnalytics";
import OutletOrders from "./OutletTabs/OutletOrders";
// import OutletProducts from "./OutletTabs/OutletProducts";
import OutletPos from "./OutletTabs/OutletPos";
import OutletDetails from "./OutletTabs/OutletDetails";
import SMSIcon from "@/assets/images/misc/sms.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import EditBtnIcon from "@/assets/images/misc/pencil-icon.svg";
import CallIcon from "@/assets/images/misc/call.svg";
import BackButton from "@/components/Common/BackButton.vue";
import SalesIcon from "@/assets/images/outlets/products.svg";
import ContactPersonIcon from "@/assets/images/outlets/contact-person.svg";
import SaleIcon from "@/assets/images/outlets/sales.svg";
import LogsIcon from "@/assets/images/outlets/logs.svg";
import AnalyticsIcon from "@/assets/images/events/analytics-icon.svg";
import PackagesIcon from "@/assets/images/outlets/details.svg";
import OutletAddEditModal from "@/views/Outlets/OutletAddEditModal.vue";
export default {
  components: {
    OutletAddEditModal,
    PackagesIcon, AnalyticsIcon, SalesIcon,SaleIcon,LogsIcon,
    BackButton, CallIcon, EditBtnIcon, SvgIcon, SMSIcon,
    CustomerAnalysis,
    // OutletSales,
    OutletOrders,
    OutletLogs,
    OutletAnalytics,
    // OutletProducts,
    OutletPos,
    OutletDetails,
    ContactPersonIcon
  },
  created() {
    if (typeof this.$route.params.data != "undefined") {
      this.id = parseInt(atob(this.$route.params.data));
      this.getOutletDetails();
    }
  },


  mounted() {
    this.getVenueRolesList();
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data.filter(
          (service) => service.name != "POS"
      );
    },
  },
  data() {
    return {
      tab: "details",
      id: null,
      image: null,
      outlet: {},
      packages: [],
      roles:[],
      venueServiceIds: [],
      addOutletDialog:false,
      params: { venue_service_ids: [], product_ids: [], product_type_ids: [2] },
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
    };
  },
  methods: {

    getVenueRolesList() {
      this.$http
          .get("venues/roles")
          .then((response) => {
            if (response.status == 200) this.roles = response.data.data;
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    editOutlet(){
      this.addOutletDialog = true;
    },
    cancelOutlet(){
      this.addOutletDialog = false;
      this.getOutletDetails();

    },
    confirmDelete(data) {
      this.confirmModel = {
        id: data.id,
        title: `Do you want to ${
            data.status_id == 1 ? "delete" : "reactivate"
        } this outlet?`,
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "outlet",
        data: {
          flag: data.flag ? true : false,
        },
      };
    },

    changeOutletStatus(id) {
      this.$http
          .put(`venues/outlets/${id}/status`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
             this.getOutletDetails()
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },

    getOutletDetails() {
      this.$http
        .get("venues/outlets/" + this.id)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.outlet = response.data.data;
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    gotoOutlet() {
      this.$router.push({
        name: "Outlets",
      });
    },
    confirmActions(data) {
      if (data.type == "outlet_customer"){
        this.$forceUpdate();
        this.confirmModel.id = null;
      }else   if (data.type == "outlet"){
        this.$forceUpdate();
        this.confirmModel.id = null;
        this.changeOutletStatus(data.id)
      }
        //  this.changeOutletCustomerStatus(data.id);

    },

  },
};
</script>

<style scoped>
.tab_active {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
</style>
