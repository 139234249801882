<template>
  <v-container fluid>
    <v-row>
      <v-col md="4" class="ml-3 mt-3">
        <date-period
          :dense="true"
          :dateType="dateToggle"
          @periodChange="dateChange"
          class-name="q-text-field shadow-0"
        ></date-period>
      </v-col>
      <v-spacer></v-spacer>
      <v-col md="3" style="text-align: center">
        <v-btn-toggle
          borderless
          class="q-tabs "
          v-model="dateToggle"
          :mandatory="mandatory"
        >
          <v-btn height="54" value="DATE">Day</v-btn>
          <v-btn height="54" value="MONTH">Month</v-btn>
          <v-btn height="54" value="YEAR">Year</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-col cols="12">
        <BarChart
          :exportPermission="exportPermission"
          v-bind="bookings"
        ></BarChart>
      </v-col>

      <v-col cols="12">
        <BarChart
          :exportPermission="exportPermission"
          v-bind="sales"
        ></BarChart>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BarChart from "@/components/Chart/BarChart";
import moment from "moment";
export default {
  components: {
    BarChart,
  },
  props: {
    outletId: {
      type: Number,
      default: null,
    },
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
    "export-permission": { type: Boolean, default: true },
    "stack-type": { type: String, default: "all" }, //service,type,all
    reload: { type: Boolean, default: false },
  },
  watch: {
    params: {
      immediate: true,
      handler(params) {
        console.log(params);
      },
    },
    stackType: {
      immediate: true,
      handler() {
        this.callGraph();
      },
    },
    stack(val) {
      this.$emit("stackChange", val);
    },
    reload: {
      immediate: true,
      handler(val) {
        if (val) {
          this.callGraph();
        }
      },
    },
  },
  data() {
    return {
      agePieChart: null,
      genderPieChart: null,
      countryPieChart: null,
      dateToggle: "DATE",
      date1: moment()
        .subtract(15, "days")
        .format("YYYY-MM-DD"),
      date2: moment().format("YYYY-MM-DD"),
      bookings: {
        heading: "Bookings",
        type: "totalVisits",
        fullscreenFlag: true,
        yTitle: "Bookings",
        xTitle: "Date",
        chartData: [],
        exportData: [],
        loader: true,
        heighest: 0,
      },
      sales: {
        heading: "Sales",
        type: "totalSales",
        fullscreenFlag: true,
        yTitle: "Sales",
        xTitle: "Date",
        chartData: [],
        exportData: [],
        loader: true,
        heighest: 0,
      },
      stack: "all",
      mandatory: false,
    };
  },

  methods: {
    callGraph() {
      this.getOutletAnalyticsData();
    },
    dateChange(data) {
      this.date1 = data.date1;
      this.date2 = data.date2;
      this.callGraph();
    },
    getOutletAnalyticsData() {
      this.sales.loader = true;
      let url = "?type=outlets&outlet_id=" + this.outletId;
      url += this.getApiFilter();
      this.$http
        .get(`venues/outlets/graphs${url}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;

            //Sales
            let SalesChartData = this.getGraphData(data.Sales, "Sales");
            this.sales.heighest = Math.ceil(SalesChartData.largest * 1.1);
            this.sales.chartData = SalesChartData.data;
            this.sales.exportData = data.Sales;
            this.sales.loader = false;
            this.mandatory = true;

            //Bookings
            let chartData = this.getGraphData(data.Bookings, "Bookings");
            this.bookings.heighest = Math.ceil(chartData.largest * 1.1);
            this.bookings.chartData = chartData.data;
            this.bookings.exportData = data.Bookings;
            this.bookings.loader = false;
            this.mandatory = true;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    getApiFilter() {
      let url = `&date_type=${this.dateToggle.toLowerCase()}&start_date=${
        this.date1
      }&end_date=${this.date2}`;
      return url;
    },
    getGraphData(data, type) {
      var graphData = [];
      let largest = 0;
      if (data.length > 0) {
        let keys = Object.keys(data[0]).filter(
          (ele) => ele.toLowerCase() != "invoice_date"
        );
        let keysLength = keys.length;
        data.forEach((element, index) => {
          let di = 0;
          let lar = 0;
          for (let i = 0; i <= keysLength; i++) {
            if (index == 0) {
              graphData[i] = {};
              graphData[i].data = {};
              graphData[i].name = keys[i];
              if (element[keys[i]]) {
                lar = lar + parseInt(element[keys[i]]);
              }
              graphData[i].data[element.invoice_date] = element[keys[i]];
              graphData[keysLength] = {};
              graphData[keysLength].name = "Total";
              graphData[keysLength].data = {};
              graphData[keysLength].data[element.invoice_date] = 0;
              di = di + 1;
            } else {
              if (element[keys[i]]) {
                lar = lar + parseInt(element[keys[i]]);
              }
              graphData[i].data[element.invoice_date] = element[keys[i]];
              di = di + 1;
            }
          }
          graphData[keysLength].data[element.invoice_date] = 0;
          if (largest < lar) {
            largest = lar;
          }
        });
        if (keysLength == 1 && keys[0] == type) {
          graphData[0].library = {};
          graphData[0].library.datalabels = {
            color: "rgba(0,0,49)",
            align: "end",
            anchor: "end",
            font: {
              size: 12,
              weight: 700,
            },
          };
        }

        if (this.stackType != "all") {
          graphData[keysLength].library = {};
          graphData[keysLength].library.datalabels = {
            color: function() {
              return "rgba(0,0,49)";
            },
            formatter: (value, ctx) => {
              let total = ctx.chart.$totalizer.totals[ctx.dataIndex];
              if (type == "Bookings") {
                let data = new Intl.NumberFormat("en-US", {
                  maximumSignificantDigits: 4,
                }).format(total);
                return data + (total > 1 ? " bookings" : " booking");
              } else if (type == "Sales") {
                var formatter = new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: this.$store.getters.currencyCode,
                  minimumFractionDigits: 0,
                });
                return formatter.format(total);
              } else {
                return new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: this.$store.getters.currencyCode,
                  // maximumSignificantDigits: 3,
                }).format(total);
              }
            },
            font: {
              size: 12,
              weight: 700,
            },
            align: "end",
            anchor: "end",
            display: function(ctx) {
              return (
                ctx.datasetIndex === ctx.chart.$totalizer.utmost &&
                ctx.chart.$totalizer.totals[ctx.dataIndex] !== 0
              );
            },
          };
        }
      }
      return { data: graphData, largest: largest };
    },
  },
};
</script>

<style></style>
